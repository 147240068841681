import { EmptyAction, ServiceAction } from '../index';
import { SuccessfulSubscriptionResponse } from '../../responseTypes/subscriptions.responseTypes';
import { FailedSubscriptionAction } from './createSubscription.actions';

export const CREATE_CUSTOMER_REQUEST = 'Request to Create Customer';

export const CREATE_CUSTOMER_SUCCESSFUL_ACTION = 'Successfully Created Customer';

export interface CreateCustomerResponse {
  customer:{
    id: string,
    email: string,
  }
}

export type CreateCustomerRequestAction = EmptyAction;

export type CreateCustomerSuccessFullAction = ServiceAction<CreateCustomerResponse>;
export type SuccessfulSubscriptionAction = ServiceAction<SuccessfulSubscriptionResponse>;

export type PaymentsAction =
    CreateCustomerSuccessFullAction |
    SuccessfulSubscriptionAction |
    FailedSubscriptionAction;
