import { connect, ConnectedProps } from 'react-redux';
import {
  createCustomerRequestAction,
} from '../../services/actions/subscriptions/createCustomer.actions';
import { PaymentsState } from '../../state/subscriptions-state.interfaces';
import { defaultPaymentsState } from '../../state/subscriptions-state.defaults';
import { CreateSubscriptionRequestType } from '../../services/requestTypes/subscriptions.requestTypes';
import { createSubscriptionRequestAction } from '../../services/actions/subscriptions/createSubscription.actions';

const mapState = (state:PaymentsState = defaultPaymentsState) => {
  const { paymentsState } = state;
  const {
    customer,
    customerIsCreated,
    successfullySubscribed,
    successfulUserSubscription,
    pendingSubscriptionRequest,
    paymentFailureReason,
  } = paymentsState;

  return {
    customerIsCreated,
    customer,
    successfullySubscribed,
    successfulUserSubscription,
    showLoadingDialog: pendingSubscriptionRequest && !paymentFailureReason,
    showPaymentFailureMessage: (paymentFailureReason && paymentFailureReason !== ''),
    paymentFailureReason,
  };
};

const mapDispatch = {
  createCustomer: () => createCustomerRequestAction(),
  createSubscription: (
    subscriptionRequest: CreateSubscriptionRequestType,
  ) => createSubscriptionRequestAction(subscriptionRequest),
  submitPayment: () => {},
};

export const PaymentsStateWrapper = connect(mapState, mapDispatch);

export type PaymentsReduxProps = ConnectedProps<typeof PaymentsStateWrapper>;
