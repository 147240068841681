import { connect, ConnectedProps } from 'react-redux';
import { State } from '../state/authentication-state.interfaces';
import { defaultHeaderInfoState } from '../state/authentication-state.defaults';

const mapState = (state: State) => {
  const { headerInfoState = defaultHeaderInfoState } = state;
  return {
    ...headerInfoState,
    loadSignInPage: headerInfoState?.loadSignInPage,
    isUserAuthenticated: headerInfoState?.loadSignInPage,
  };
};

export const HeaderStateWrapper = connect(mapState);

export type HeaderReduxProps = ConnectedProps<typeof HeaderStateWrapper>;
