import { ServiceAction } from '../index';
import { RegisterNewUserRequest } from '../../requestTypes/authentication.requestTypes';
import { RegisterNewUserResponse } from '../../responseTypes/authentication.responseTypes';

export const REGISTER_NEW_USER_REQUEST_ACTION = 'Register New User Action Request';
export const REGISTER_NEW_USER_SUCCESSFUL_RESPONSE = 'New User Registration Request was Successful';
export const REGISTER_NEW_USER_FAILED = 'Failed to register new user';
export const PENDING_USER_REGISTRATION_REQUEST = 'Registration Request Pending';
export const RESET_ACCOUNT_ALREADY_EXISTS = 'Reset Account Already exists';
export const RESET_INLINE_ERROR = 'Reset Inline Error';
export type PendingRequestAction = ServiceAction<string>;

export type RegisterNewUserAction = ServiceAction<RegisterNewUserRequest>;
export type RegisterNewUserResponseAction = ServiceAction<RegisterNewUserResponse>;

export type RegistrationServiceAction =
    RegisterNewUserAction |
    PendingRequestAction |
    RegisterNewUserResponseAction;
