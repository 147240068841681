import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import TranslationsProxy from '../translations/translationsProxy';
import { USER_ALREADY_EXISTS, VALIDATE_REGISTRATION_PERIOD_EXPIRED } from '../constants/error.constants';

type MessageComponent = (data?:any)=> JSX.Element;

interface LoadingDialogProps {
  messageCode?: string,
  alertDialog: boolean,
  messageComponent?: MessageComponent | undefined,
  messageComponentData?: object | undefined,
  selfDestructionSettings?:{
    messageComponent?: ()=> JSX.Element,
    timeOut: number,
    callback?: ()=>void
  } | undefined
}

const defaultProps: Partial<LoadingDialogProps> = {
  messageCode: '',
  messageComponent: undefined,
  messageComponentData: undefined,
  selfDestructionSettings: undefined,
};

const GifContainerCentered = styled.a`
    display: block;
    position: relative;
    width: 80%;
    left: 10%;
    height: 80%;
    top: 10%;
`;

const SignInUrl = styled.a`
    background-color: #8E85D3;
    color: white;
    line-height: normal;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;
    float: right;

`;

const MessageComponentContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LinkContainerCentered = styled.div`
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const DialogTitleCentered = styled(DialogTitle)`
    left: 20%;
`;

function CountDownLoadingDialog(props:LoadingDialogProps) {
  const {
    selfDestructionSettings,
    messageComponent,
    messageComponentData,
    messageCode,
    alertDialog,
  } = props;
  const [open, setOpen] = React.useState(alertDialog);
  const [secondsToSelfDestruct] = React.useState(selfDestructionSettings?.timeOut);
  const [isActive] = React.useState(
    selfDestructionSettings
      && selfDestructionSettings?.timeOut > 0,
  );
  const [seconds, setSeconds] = React.useState(0);

  const MessageComponent = messageComponent;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let interval:number = 0;
    if (isActive && secondsToSelfDestruct && seconds < secondsToSelfDestruct) {
      interval = setInterval(() => {
        setSeconds((sec) => sec + 1);
      }, 1000);
    } else if (isActive) {
      setOpen(false);
      clearInterval(interval);
      if (selfDestructionSettings?.callback) {
        selfDestructionSettings.callback();
      }
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, selfDestructionSettings, secondsToSelfDestruct]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleCentered id="alert-dialog-title">
        {TranslationsProxy.getText(messageCode)}
      </DialogTitleCentered>
      <DialogContent>
        {MessageComponent
                    && (
                    <div>
                      <MessageComponentContainer>
                        {messageComponentData
                          && (
                          <MessageComponent
                            {...messageComponentData}
                          />
                          )}
                        {!messageComponentData && <MessageComponent /> }
                      </MessageComponentContainer>
                      {seconds && seconds > 0 && secondsToSelfDestruct
                        && (
                        <MessageComponentContainer>
                          In
                          {' '}
                          {secondsToSelfDestruct - seconds}
                          {' '}
                          seconds
                        </MessageComponentContainer>
                        )}
                    </div>
                    )}
        {messageCode === USER_ALREADY_EXISTS
                && (
                <LinkContainerCentered>
                  <SignInUrl href="/signIn">
                    SIGN IN
                  </SignInUrl>
                </LinkContainerCentered>
                )}
        {messageCode === VALIDATE_REGISTRATION_PERIOD_EXPIRED
                && (
                <LinkContainerCentered>
                  <SignInUrl href="/signUp">
                    SIGN UP
                  </SignInUrl>
                </LinkContainerCentered>
                )}
        <GifContainerCentered>
          <Image src="/artifacts/hamster-loading.gif" />
        </GifContainerCentered>
      </DialogContent>
    </Dialog>
  );
}

CountDownLoadingDialog.defaultProps = defaultProps;

export default CountDownLoadingDialog;
