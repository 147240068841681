import { call, put } from 'redux-saga/effects';
import {
  fetchApplicationSessionId,
  fetchRefreshTokenFromLocalStorage,
  setAccessToken, setPermissions,
  setRefreshToken, setRoles, setUsername,
} from '../../localStorageProxy';
import ApiProxy from '../../apiProxy';
import { RefreshTokenRequest } from '../../requestTypes/authentication.requestTypes';
import { APPLICATION_NAME } from '../../../constants/application.constants';
import { RefreshTokenResponse } from '../../responseTypes/authentication.responseTypes';
import goToSignInPage from '../../actions/ui/header.actions';

const storeDataInLS = (data:RefreshTokenResponse):void => {
  setAccessToken(data.token);
  setRefreshToken(data.refreshToken);
  setRoles(data.roles);
  setPermissions(data.permissions);
  setUsername(data.username);
};

let maxRetries = 3;

function* refreshToken(refreshTokenRequest:RefreshTokenRequest) {
  const response:RefreshTokenResponse = yield call(ApiProxy.refreshTokenApi, refreshTokenRequest);
  if (response.success) {
    storeDataInLS(response);
    return response;
  }
  return yield put(goToSignInPage());
}

export default function* refreshTokenApiCall() {
  const refreshTokenString:string = fetchRefreshTokenFromLocalStorage();
  const application:string = APPLICATION_NAME;
  const deviceId:string = fetchApplicationSessionId();
  const refreshTokenRequest:RefreshTokenRequest = {
    data: {
      application,
      deviceId,
      refreshToken: refreshTokenString,
    },
  };
  try {
    return yield refreshToken(refreshTokenRequest);
  } catch (e) {
    if (e instanceof Error && maxRetries > 0) {
      maxRetries -= 1;
      return yield refreshToken(refreshTokenRequest);
    }
    return yield put(goToSignInPage());
  }
}
