import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESSFUL_ACTION, CreateCustomerRequestAction,
  CreateCustomerResponse,
  CreateCustomerSuccessFullAction,
} from './createCustomer.actionTypes';

export const createCustomerRequestAction = () : CreateCustomerRequestAction => ({
  type: CREATE_CUSTOMER_REQUEST,
});

export const createCustomerSuccessfulAction = (
  response: CreateCustomerResponse,
): CreateCustomerSuccessFullAction => ({
  type: CREATE_CUSTOMER_SUCCESSFUL_ACTION,
  payload: {
    ...response,
  },
});
