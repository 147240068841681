import {
  GET_SUBSCRIPTIONS_REQUEST, GET_SUBSCRIPTIONS_SUCCESSFUL,
  GetSubscriptionsAction, GetSubscriptionsResponse, GetSubscriptionsSuccesfulAction,
} from './getSubscriptions.actionTypes';

export const getSubscriptionsAction = (): GetSubscriptionsAction => ({
  type: GET_SUBSCRIPTIONS_REQUEST,
  payload: '',
});

export const getSubscriptionsSuccessfulAction = (
  response: GetSubscriptionsResponse,
): GetSubscriptionsSuccesfulAction => ({
  type: GET_SUBSCRIPTIONS_SUCCESSFUL,
  payload: {
    subscriptions: response.subscriptions,
    userIsAlreadySubscribed: response.userIsAlreadySubscribed,
  },
});
