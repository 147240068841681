import React, { useCallback, useState } from 'react';
import {
  Avatar, Box, Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import styled from 'styled-components';
import { SignUpReduxProps, SignUpStateWrapper } from './SignUp.cc';
import { fetchApplicationSessionId } from '../../services/localStorageProxy';
import LoadingDialog from '../../common/LoadingDialog';
import TranslationsProxy from '../../translations/translationsProxy';
import { VALIDATE_REGISTRATION_COMPLETE } from '../../constants/message.constants';
import { emailIsValid } from '../../utlis/data.utils';
import { APPLICATION_NAME } from '../../constants/application.constants';
import Copyright from '../../common/Copyright.ui';
import useCommonStyles from './authentication.styles';

export interface SignUpUIProps extends SignUpReduxProps {

}

const InlineError = styled.div`
    color: red;
`;

function SignUpUI(props:SignUpUIProps) {
  const classes = useCommonStyles();

  const initialState = {
    isPasswordValid: false,
    isPasswordEmpty: true,
    signUpButtonEnabled: false,
    isEmailValid: false,
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    accountAlreadyExists: false,
  };

  const {
    registrationRequestStatus,
    showLoadingDialog,
    showSuccessfulRegistrationDialog,
  } = props;

  const [state, setState] = useState(initialState);

  const validateEmail = useCallback((e) => {
    const isEmailValid = emailIsValid(e.target.value);
    props.resetInlineError();
    setState({
      ...state,
      isEmailValid,
      email: (isEmailValid) ? e.target.value : '',
      signUpButtonEnabled: isEmailValid && Boolean(state.password) && state.isPasswordValid,
    });
  },
  [state, setState, props]);

  const setPassword = useCallback((e:{target:{value:string}}) => {
    const password = e.target.value;
    const isPasswordValid = state.confirmPassword === password;
    setState({
      ...state,
      password,
      signUpButtonEnabled: state.isEmailValid && Boolean(password) && isPasswordValid,
    });
  }, [state]);

  const validatePassword = useCallback((e) => {
    const confirmPassword = e.target.value;
    const isPasswordValid = Boolean(state.password) && state.password === confirmPassword;
    setState({
      ...state,
      isPasswordValid,
      confirmPassword,
      signUpButtonEnabled: state.isEmailValid && isPasswordValid,
    });
  }, [state]);

  const onClickSubmitButton = useCallback(() => {
    setState(initialState);
    props.signUpButtonClick({
      deviceId: fetchApplicationSessionId(),
      application: APPLICATION_NAME,
      email: state.email,
      password: state.password,
      username: state.email,
    });
  }, [state, props, initialState]);

  if (showLoadingDialog) {
    return (
      <LoadingDialog
        alertDialog
        messageCode={registrationRequestStatus.message}
      />
    );
  }

  if (showSuccessfulRegistrationDialog) {
    return (
      <LoadingDialog
        alertDialog
        messageCode={VALIDATE_REGISTRATION_COMPLETE}
      />
    );
  }

  const { accountAlreadyExists } = props;

  const { inlineError } = props;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {inlineError
                    && (
                    <InlineError>
                      {TranslationsProxy.getError(inlineError)}
                    </InlineError>
                    )}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          helperText={(accountAlreadyExists) ? `User with email address ${state.email} is already registered` : ''}
          name="email"
          autoComplete="email"
          autoFocus
          error={accountAlreadyExists}
          onChange={validateEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={setPassword}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="ConfirmPassword"
          type="password"
          id="confirmPassword"
          autoComplete="current-password"
          onChange={validatePassword}
          error={false}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={Boolean(inlineError) || !state.signUpButtonEnabled}
          onClick={onClickSubmitButton}
        >
          Sign Up
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignUpStateWrapper(SignUpUI);
