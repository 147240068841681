import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import LandingPageFooter from './bottom/LandingPageFooter';
import SignUpUI from './pages/authentication/SignUp.ui';
import ValidateRegistrationUI from './pages/authentication/ValidateRegistration.ui';
import SignInUI from './pages/authentication/SignIn.ui';
import ResetPasswordUI from './pages/authentication/ResetPassword.ui';
import SelectSubscriptionUI from './pages/subscriptions/SelectSubscription.ui';
import HeaderUI from './top/Header.ui';
import Payments from './pages/subscriptions/Payments.ui';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

function App() {
  return (
    <div>
      <Router>
        <HeaderUI />
        <div>
          <ul>
            <li>
              <Link to="/">Welcome Page</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          </ul>
          <hr />
          <Switch>
            <Route exact path="/home">
              <SignInUI />
            </Route>
            <Route exact path="/signIn">
              <SignInUI />
            </Route>
            <Route exact path="/signUp">
              <SignUpUI />
            </Route>
            <Route path="/validateRegistration/:validationToken">
              <ValidateRegistrationUI />
            </Route>
            <Route path="/resetPassword/step1">
              <ResetPasswordUI step={1} />
            </Route>
            <Route path="/resetPassword/step2/:resetPasswordToken">
              <ResetPasswordUI step={2} />
            </Route>
            <Route path="/subscriptions">
              <SelectSubscriptionUI />
            </Route>
            <Route path="/pay/:subscriptionId">
              <Elements stripe={stripePromise}>
                <Payments />
              </Elements>
            </Route>
          </Switch>
        </div>
      </Router>
      <LandingPageFooter />
    </div>

  );
}

export default App;
