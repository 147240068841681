import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../state/authentication-state.interfaces';
import { defaultSignInState } from '../../state/authentication-state.defaults';
import { SignInUserRequest } from '../../services/requestTypes/authentication.requestTypes';
import { signInUserRequestAction } from '../../services/actions/authentication/signIn.actions';
import resetInlineError from "../../services/actions/authentication/authentication.actions";

const mapState = (state: State) => {
  const { signInState = defaultSignInState } = state;

  return {
    loadingDialog: { ...signInState.loadingDialog },
    user: {
      ...signInState.user,
    },
    inlineError: signInState.signInFailed && signInState.signInFailed.message,
    triedEmail: signInState.signInFailed && signInState.signInFailed.email,
    failedAttempts: signInState.signInFailed && signInState.signInFailed.attempt,
  };
};

const mapDispatch = {
  signIn: (userSignInRequest: SignInUserRequest) => signInUserRequestAction(userSignInRequest),
  // resetAccountAlreadyExists: () => resetAccountAlreadyExists(),
  resetInlineError: () => resetInlineError(),
};

export const SignInStateWrapper = connect(
  mapState,
  mapDispatch,
);

export type SignInReduxProps = ConnectedProps<typeof SignInStateWrapper>;
