import React from 'react';
import {
  List, ListItemIcon, ListItemText, ListItem,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

interface ListItemProps {
  items: ListItemMembers[],
  dense: boolean,
}

interface ListItemMembers {
  primaryText: string;
  secondaryText?: string;
}

export default function TickList(props:ListItemProps) {
  const { dense, items } = props;
  return (
    <List dense={dense}>
      {
                items.map((item) => (
                  <ListItem key={item.primaryText}>
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.primaryText}
                      secondary={item?.secondaryText}
                    />
                  </ListItem>
                ))
            }

    </List>
  );
}
