import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../state/authentication-state.interfaces';
import { RegisterNewUserRequest } from '../../services/requestTypes/authentication.requestTypes';
import { RequestStatus } from '../../state/state.enums';
import { StatusCodes } from '../../services/constants/statusCodes.constants';
import { defaultRegistrationState } from '../../state/authentication-state.defaults';
import {
  registerUserAction,
  resetAccountAlreadyExists,
} from '../../services/actions/authentication/register.actions';
import resetInlineError from '../../services/actions/authentication/authentication.actions';

const mapState = (state: State) => {
  const { registrationState = defaultRegistrationState } = state;

  return {
    registrationRequestStatus: registrationState.registrationRequestStatus,
    showLoadingDialog: registrationState.registrationRequestStatus
            && registrationState.registrationRequestStatus.status === RequestStatus.PENDING,
    showSuccessfulRegistrationDialog: registrationState.registrationResponse
            && registrationState.registrationResponse.success,
    inlineError: registrationState.inlineError,
    // showResetYourPasswordMessage: registrationState.inlineError === USER_ALREADY_EXISTS,
    accountAlreadyExists: registrationState.registrationResponse
            && registrationState.registrationResponse.status === StatusCodes.SERVICE_ERROR,
  };
};

const mapDispatch = {
  signUpButtonClick: (
    userRegistrationRequest: RegisterNewUserRequest,
  ) => registerUserAction(userRegistrationRequest),
  resetAccountAlreadyExists: () => resetAccountAlreadyExists(),
  resetInlineError: () => resetInlineError(),
};

export const SignUpStateWrapper = connect(
  mapState,
  mapDispatch,
);

export type SignUpReduxProps = ConnectedProps<typeof SignUpStateWrapper>;
