import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import TranslationsProxy from '../translations/translationsProxy';
import { USER_ALREADY_EXISTS, VALIDATE_REGISTRATION_PERIOD_EXPIRED } from '../constants/error.constants';

interface LoadingDialogProps {
  messageCode?: string,
  alertDialog: boolean,
  messageComponent?: (()=> JSX.Element) | undefined,
}

const defaultProps:Partial<LoadingDialogProps> = {
  messageCode: '',
  messageComponent: undefined,
};

const GifContainerCentered = styled.a`
    display: block;
    position: relative;
    width: 80%;
    left: 10%;
    height: 80%;
    top: 10%;
`;

const SignInUrl = styled.a`
    background-color: #8E85D3;
    color: white;
    line-height: normal;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;
    float: right;

`;

const MessageComponentContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LinkContainerCentered = styled.div`
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const DialogTitleCentered = styled(DialogTitle)`
    left: 20%;
`;

function LoadingDialog(props:LoadingDialogProps) {
  const { alertDialog, messageComponent, messageCode } = props;
  const [open, setOpen] = React.useState(alertDialog);

  const MessageComponent = messageComponent;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleCentered id="alert-dialog-title">{TranslationsProxy.getText(messageCode)}</DialogTitleCentered>
      <DialogContent>
        {MessageComponent
                    && (
                    <div>
                      <MessageComponentContainer>
                        <MessageComponent />
                      </MessageComponentContainer>
                    </div>
                    )}
        {messageCode === USER_ALREADY_EXISTS
                    && (
                    <LinkContainerCentered>
                      <SignInUrl href="/signIn">
                        SIGN IN
                      </SignInUrl>
                    </LinkContainerCentered>
                    )}
        {messageCode === VALIDATE_REGISTRATION_PERIOD_EXPIRED
                && (
                <LinkContainerCentered>
                  <SignInUrl href="/signUp">
                    SIGN UP
                  </SignInUrl>
                </LinkContainerCentered>
                )}
        <GifContainerCentered>
          <Image src="/artifacts/hamster-loading.gif" />
        </GifContainerCentered>
      </DialogContent>

    </Dialog>
  );
}

LoadingDialog.defaultProps = defaultProps;

export default LoadingDialog;
