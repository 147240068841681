import { connect, ConnectedProps } from 'react-redux';
import { getSubscriptionsAction } from '../../services/actions/subscriptions/getSubscriptions.actions';
import { GetSubscriptionsState } from '../../state/subscriptions-state.interfaces';
import { defaultGetSubscriptionsState } from '../../state/subscriptions-state.defaults';

const mapState = (state: GetSubscriptionsState = defaultGetSubscriptionsState) => {
  const { subscriptions = [], userIsAlreadySubscribed } = state.getSubscriptionsState;
  return {
    showSubscriptions: subscriptions.length > 0,
    userIsAlreadySubscribed,
    subscriptions,
  };
};

const mapDispatch = {
  getSubscriptions: () => getSubscriptionsAction(),
};

export const SelectSubscriptionStateWrapper = connect(
  mapState,
  mapDispatch,
);

export type SelectSubscriptionReduxProps = ConnectedProps<typeof SelectSubscriptionStateWrapper>;
