import { all } from 'redux-saga/effects';
import getSubscriptionsWatcher from './getSubscriptions.saga';
import createCustomerWatcher from './createCustomer.saga';
import createSubscriptionWatcher from './createSubscription.saga';

export default function* subscriptionsRootWatcher() {
  yield all([
    getSubscriptionsWatcher(),
    createCustomerWatcher(),
    createSubscriptionWatcher(),
  ]);
}
