import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { HeaderReduxProps, HeaderStateWrapper } from './Header.cc';

const LandingPageHeaderWrapper = styled.div`
    padding-top: 20px;
    margin: 0 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoStyled = styled.img`
    width: 300px;
    height: 100px;
`;

const SignInButton = styled.a`
    background-color: #8E85D3;
    color: white;
    line-height: normal;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;
    float: right;
`;

function HeaderUI(props: HeaderReduxProps) {
  const history = useHistory();
  const { loadSignInPage } = props;
  if (loadSignInPage) {
    history.push('/signIn');
  }

  return (
    <LandingPageHeaderWrapper>
      <LogoStyled src="/artifacts/chomiko-logo192.svg" />
      <SignInButton href="/signIn">SIGN IN</SignInButton>
    </LandingPageHeaderWrapper>
  );
}

export default HeaderStateWrapper(HeaderUI);
