import { GetSubscriptionsState, PaymentsState } from './subscriptions-state.interfaces';
import { AccessLevel, Cancel, Currency } from '../constants/Subscription.enums';

export const defaultGetSubscriptionsState: GetSubscriptionsState = {
  getSubscriptionsState: {
    subscriptions: [],
    userIsAlreadySubscribed: false,
    showSubscriptions: false,
  },
};

export const defaultPaymentsState: PaymentsState = {
  paymentsState: {
    customer: {
      id: '',
      email: '',
    },
    customerIsCreated: false,
    successfullySubscribed: false,
    pendingSubscriptionRequest: false,
    paymentFailureReason: '',
    successfulUserSubscription: {
      freeTrialDays: 0,
      currency: Currency.EUR,
      accessLevel: AccessLevel.NONE,
      updateInterval: 0,
      price: 0,
      cancel: Cancel.ANYTIME,
      freeTrialEndDate: Date.now(),
      subscriptionPeriodEnd: Date.now(),
    },
  },
};
