import { SignInUserRequest } from '../../requestTypes/authentication.requestTypes';
import {
  SIGN_IN_USER_REQUEST, SIGN_IN_REQUEST_IN_PROGRESS,
  SIGN_IN_USER_SUCCESS, SignInFailedAction,
  SignInSuccessfulAction,
  SignInUserRequestAction, SignInRequestInProgressAction, SIGN_IN_USER_FAILED,
} from './signIn.actionTypes';
import {
  SignInFailedResponse,
  SignInUserSuccessfulResponse,
} from '../../responseTypes/authentication.responseTypes';

export const signInUserRequestAction = (
  userSignInRequest: SignInUserRequest,
): SignInUserRequestAction => ({
  type: SIGN_IN_USER_REQUEST,
  payload: {
    ...userSignInRequest,
  },
});

export const signInUserRequestInProgressAction = (
  message:string,
): SignInRequestInProgressAction => ({
  type: SIGN_IN_REQUEST_IN_PROGRESS,
  payload: {
    message,
  },
});

export const signInSuccessfulAction = (
  userSignInSuccessfulResponse: SignInUserSuccessfulResponse,
): SignInSuccessfulAction => ({
  type: SIGN_IN_USER_SUCCESS,
  payload: userSignInSuccessfulResponse,
});

export const signInFailedAction = (
  userSignInFailedResponse: SignInFailedResponse,
): SignInFailedAction => ({
  type: SIGN_IN_USER_FAILED,
  payload: userSignInFailedResponse,
});
