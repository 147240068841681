import { ServiceAction } from '../index';
import { ValidateReigstrationRequest } from '../../requestTypes/authentication.requestTypes';
import {
  ValidateRegistrationSuccessfulResponse,
} from '../../responseTypes/authentication.responseTypes';

export const VALIDATE_REGISTRATION_SERVICE_FAILED = 'Validate Registration Failed';
export const VALIDATE_REGISTRATION_SUCCESSFUL_RESPONSE = 'Validate Registration was successful';
export const VALIDATE_REGISTRATION_TOKEN_ACTION = 'Will Validate the Registration';

export type ValidateRegistrationRequestAction = ServiceAction<ValidateReigstrationRequest>;
export type ValidateRegistrationSuccessfulResponseAction =
    ServiceAction<ValidateRegistrationSuccessfulResponse>;

export interface ValidateRegistrationError {
  message: string,
}

export type ValidateRegistrationErrorAction = ServiceAction<ValidateRegistrationError>;

export type ValidateRegistrationServiceAction =
    ValidateRegistrationRequestAction |
    ValidateRegistrationSuccessfulResponseAction |
    ValidateRegistrationErrorAction;
