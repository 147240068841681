import { call, put } from 'redux-saga/effects';
import { EmptyAction, ServiceAction } from '../actions';
import refreshTokenApiCall from './authentication/refreshToken.saga';
import { GenericRequest } from '../requestTypes/common.requestTypes';
import goToSignInPage from '../actions/ui/header.actions';

type ServiceActionType<ActionDataType> = (...args: any[])=>ServiceAction<ActionDataType>;
type DenialOfServiceAction<T> = ServiceAction<T> | EmptyAction;

export function* executeAuthenticatedSaga<FollowupActionDataType, DenialOfActionType = never>(
  apiFunction:(...args: any[]) => any,
  request:GenericRequest,
  followupAction?: ServiceActionType<FollowupActionDataType>,
  denialOfServiceAction:(...args:any[])=>DenialOfServiceAction<DenialOfActionType> = goToSignInPage,
  denialOfServiceActionArguments: any[] = [],
) {
  let response = yield call(apiFunction, request);
  if (!response && request.requiresAuthentication) {
    const refreshTokenResponse = yield call(refreshTokenApiCall);
    if (!refreshTokenResponse) {
      yield put(denialOfServiceAction(denialOfServiceActionArguments));
    }
    response = yield call(apiFunction, request);
  }
  if (response && followupAction !== undefined) {
    yield put(followupAction(response));
  } else if (!response) {
    yield put(denialOfServiceAction(denialOfServiceActionArguments));
  }
}
