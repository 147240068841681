import { takeLatest } from 'redux-saga/effects';
import {
  GET_SUBSCRIPTIONS_REQUEST,
  GetSubscriptionsResponse,
} from '../../actions/subscriptions/getSubscriptions.actionTypes';
import ApiProxy from '../../apiProxy';
import {
  getSubscriptionsSuccessfulAction,
} from '../../actions/subscriptions/getSubscriptions.actions';
import { GetSubscriptionsRequestType } from '../../requestTypes/subscriptions.requestTypes';
import { executeAuthenticatedSaga } from '../sagas.utils';

function* getSubscriptions() {
  const getSubscriptionsRequest:GetSubscriptionsRequestType = {
    requiresAuthentication: true,
  };
  yield executeAuthenticatedSaga<GetSubscriptionsResponse>(
    ApiProxy.getSubscriptionsApi,
    getSubscriptionsRequest,
    getSubscriptionsSuccessfulAction,
  );
}

export default function* getSubscriptionsWatcher() {
  yield takeLatest(GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
}
