import { takeLatest, put, call } from 'redux-saga/effects';
import { SIGN_IN_USER_REQUEST, SignInUserRequestAction } from '../../actions/authentication/signIn.actionTypes';
import ApiProxy from '../../apiProxy';
import {
  SignInFailedResponse,
  SignInUserResponse,
  SignInUserSuccessfulResponse,
} from '../../responseTypes/authentication.responseTypes';
import {
  signInFailedAction,
  signInSuccessfulAction,
  signInUserRequestInProgressAction,
} from '../../actions/authentication/signIn.actions';
import { SIGN_IN_REQUEST_IN_PROGRESS_MESSAGE } from '../../../constants/message.constants';
import {
  setAccessToken, setPermissions, setRefreshToken, setRoles, setUsername,
} from '../../localStorageProxy';

function storeTokens(data:SignInUserSuccessfulResponse) {
  setRoles(data.roles || []);
  setPermissions(data.permissions);
  setAccessToken(data.token);
  setRefreshToken(data.refreshToken);
  setUsername(data.username);
}

function* signInUser(userRequest: SignInUserRequestAction) {
  const { attempt = 0 } = userRequest.payload;
  yield put(signInUserRequestInProgressAction(SIGN_IN_REQUEST_IN_PROGRESS_MESSAGE));
  try {
    const response:SignInUserResponse = yield call(ApiProxy.signInApi, userRequest.payload);
    if (response.success) {
      yield call(storeTokens, response as SignInUserSuccessfulResponse);
      yield put(signInSuccessfulAction(response as SignInUserSuccessfulResponse));
    } else {
      const actionResponse:SignInFailedResponse = {
        ...response,
        email: userRequest.payload.username,
        attempt: attempt + 1,
      } as SignInFailedResponse;

      yield put(signInFailedAction(actionResponse));
    }
  } catch (error) {
    const errorResponse: SignInFailedResponse = {
      attempt: userRequest.payload.attempt + 1,
    } as SignInFailedResponse;
    yield put(signInFailedAction(errorResponse));
  }
}

export default function* signInUserWatcher() {
  yield takeLatest(SIGN_IN_USER_REQUEST, signInUser);
}
