import { CreateSubscriptionRequestType } from '../../requestTypes/subscriptions.requestTypes';
import { ServiceAction, ServiceMessagePayload } from '../index';
import { CreateSubscriptionResponse, SuccessfulSubscriptionResponse } from '../../responseTypes/subscriptions.responseTypes';

export const CREATE_SUBSCRIPTION_REQUEST_ACTION = 'Dispatch Create Subscription Action';
export const PENDING_SUBSCRIPTION_REQUEST_ACTION = 'Waiting for Subscription Response';
export const SUCCESSFUL_SUBSCRIPTION_ACTION = 'Creation of subscription was successful';
export const PROCESS_SUCCESSFUL_SUBSCRIPTION_RESPONSE = 'Proccess successful Response from Create Subscription Request';
export const SUBSCRIPTION_CREATION_FAILED_ACTION = 'Subscription Failed';

export type CreateSubscriptionRequestAction = ServiceAction<CreateSubscriptionRequestType>;

export type ProcessSuccessfulSubscriptionResponseAction = ServiceAction<CreateSubscriptionResponse>;

export type SuccessfulSubscriptionAction = ServiceAction<SuccessfulSubscriptionResponse>;

export type FailedSubscriptionAction = ServiceAction<ServiceMessagePayload>;

export const createSubscriptionRequestAction = (
  request: CreateSubscriptionRequestType,
):CreateSubscriptionRequestAction => ({
  type: CREATE_SUBSCRIPTION_REQUEST_ACTION,
  payload: request,
});

export const pendingSubscriptionRequestAction = () => ({
  type: PENDING_SUBSCRIPTION_REQUEST_ACTION,
});

export const successfulSubscriptionAction = (
  successfulSubscription:SuccessfulSubscriptionResponse,
):SuccessfulSubscriptionAction => ({
  type: SUCCESSFUL_SUBSCRIPTION_ACTION,
  payload: successfulSubscription,
});

export const processSuccessfulSubscriptionResponseAction = (
  createSubscriptionResponse: CreateSubscriptionResponse,
):ProcessSuccessfulSubscriptionResponseAction => ({
  type: PROCESS_SUCCESSFUL_SUBSCRIPTION_RESPONSE,
  payload: createSubscriptionResponse,
});

export const createSubscriptionFailedAction = (message:string): FailedSubscriptionAction => ({
  type: SUBSCRIPTION_CREATION_FAILED_ACTION,
  payload: { message },
});
