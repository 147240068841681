import { RegisterNewUserRequest } from '../../requestTypes/authentication.requestTypes';
import {
  PENDING_USER_REGISTRATION_REQUEST,
  PendingRequestAction, REGISTER_NEW_USER_FAILED,
  REGISTER_NEW_USER_REQUEST_ACTION, REGISTER_NEW_USER_SUCCESSFUL_RESPONSE,
  RegisterNewUserAction, RESET_ACCOUNT_ALREADY_EXISTS,
} from './register.actionTypes';
import { RegisterNewUserResponse } from '../../responseTypes/authentication.responseTypes';

export const registerUserAction = (
  userRegistrationRequest: RegisterNewUserRequest,
):RegisterNewUserAction => ({
  type: REGISTER_NEW_USER_REQUEST_ACTION,
  payload: userRegistrationRequest,
});

export const pendingUserRegistrationRequest = (message:string):PendingRequestAction => ({
  type: PENDING_USER_REGISTRATION_REQUEST,
  payload: message,
});

export const successfulUserRegistrationRequest = (
  userRegistrationResponse: RegisterNewUserResponse,
) => ({
  type: REGISTER_NEW_USER_SUCCESSFUL_RESPONSE,
  payload: userRegistrationResponse,
});

export const failedUserRegistrationRequest = (
  userRegistrationResponse: RegisterNewUserResponse,
) => ({
  type: REGISTER_NEW_USER_FAILED,
  payload: userRegistrationResponse,
});

export const resetAccountAlreadyExists = () => ({
  type: RESET_ACCOUNT_ALREADY_EXISTS,
});
