import { combineReducers } from 'redux';
import {
  registrationReducer,
  validateRegistrationReducer,
  signInReducer,
  resetPasswordReducer, headerInfoReducer,
} from './authentication.reducers';

import {
  getSubscriptionsReducer, paymentsReducer,
} from './subscriptions.reducers';

const reducers = combineReducers(
  {
    registrationState: registrationReducer,
    validateRegistrationState: validateRegistrationReducer,
    signInState: signInReducer,
    resetPasswordState: resetPasswordReducer,
    getSubscriptionsState: getSubscriptionsReducer,
    paymentsState: paymentsReducer,
    headerInfoState: headerInfoReducer,
  },
);

export default reducers;
