import {
  ResetPasswordStep1Request, ResetPasswordStep2Request,
} from '../../requestTypes/authentication.requestTypes';
import {
  RESET_PASSWORD_STEP1_REQUEST_ACTION,
  RESET_PASSWORD_REQUEST_IN_PROGRESS_ACTION,
  ResetPasswordRequestInProgressAction,
  ResetPasswordStep1RequestAction,
  RESET_PASSWORD_STEP1_SUCCESSFUL_ACTION,
  ResetPasswordStep1SuccessfulAction,
  RESET_PASSWORD_FAILED_ACTION,
  ResetPasswordFailedAction,
  ResetPasswordStep2RequestAction,
  RESET_PASSWORD_STEP2_REQUEST_ACTION,
  ResetPasswordStep2SuccessfulAction, RESET_PASSWORD_STEP2_SUCCESSFUL_ACTION,
} from './resetPassword.actionTypes';

export const resetPasswordStep1Request = (
  request:ResetPasswordStep1Request,
):ResetPasswordStep1RequestAction => ({
  type: RESET_PASSWORD_STEP1_REQUEST_ACTION,
  payload: { ...request },
});

export const resetPasswordStep2Request = (
  request:ResetPasswordStep2Request,
):ResetPasswordStep2RequestAction => ({
  type: RESET_PASSWORD_STEP2_REQUEST_ACTION,
  payload: { ...request },
});

export const resetPasswordRequestInProgressAction = (
  message:string,
): ResetPasswordRequestInProgressAction => ({
  type: RESET_PASSWORD_REQUEST_IN_PROGRESS_ACTION,
  payload: {
    message,
  },
});

export const resetPasswordStep1SuccessfulAction = (
  message:string,
):ResetPasswordStep1SuccessfulAction => ({
  type: RESET_PASSWORD_STEP1_SUCCESSFUL_ACTION,
  payload: {
    message,
  },
});

export const resetPasswordStep2SuccessfulAction = (
  message:string,
):ResetPasswordStep2SuccessfulAction => ({
  type: RESET_PASSWORD_STEP2_SUCCESSFUL_ACTION,
  payload: {
    message,
  },
});

export const resetPasswordFailedAction = (message:string):ResetPasswordFailedAction => ({
  type: RESET_PASSWORD_FAILED_ACTION,
  payload: {
    message,
  },
});
