import React, { useEffect } from 'react';
import {
  Button,
  Card, CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { SelectSubscriptionReduxProps, SelectSubscriptionStateWrapper } from './SelectSubscription.cc';
import { Subscription } from '../../state/subscriptions-state.interfaces';
import { chunkData } from '../../utlis/data.utils';
import TranslationsProxy from '../../translations/translationsProxy';
import {
  SUBSCRIPTION_BP1,
  SUBSCRIPTION_BP2, SUBSCRIPTION_BP3, SUBSCRIPTION_CURRENCY_KEY, SUBSCRIPTION_FREE_TRIAL_KEY,
  SUBSCRIPTION_HEADER,
  SUBSCRIPTION_PERIOD_KEY, SUBSCRIPTION_PRICE_TEXT, SUBSCRIPTION_SUBSCRIBE_BUTTON,
  SUBSCRIPTION_SUBTITLE, SUBSCRIPTION_UPDATE_SUBSCRIPTION_KEY,
} from '../../constants/uiText.contants';
import TickList from '../../ui/TickList';
import { setSelectedSubscription } from '../../services/localStorageProxy';

const SUBSCRIPTIONS_PER_ROW = 3;

interface SubscriptionsGroup {
  group: Subscription[],
  groupId: number,
}

interface SubscriptionComponentProps {
  subscription: Subscription,
  subscriptionIndex: number,
  groupIndex: number
  onclick: ()=>void,
}

const SubscriptionsContainer = styled(Grid)`
    position: relative;
    width: 50%;
    left:25%;
`;

const groupSubscriptions = (
  subscriptions:Subscription[],
):SubscriptionsGroup[] => chunkData<Subscription>(subscriptions, SUBSCRIPTIONS_PER_ROW)
  .map((c, index) => ({ group: c, groupId: index }));

const SubscriptionsMainComponent = () => (
  <Grid
    container
    spacing={3}
    justify="center"
    direction="column"
    alignItems="center"
  >
    <Typography variant="h6">
      {TranslationsProxy.getUiText(SUBSCRIPTION_HEADER)}
    </Typography>
    <Typography variant="h6">
      {TranslationsProxy.getUiText(SUBSCRIPTION_SUBTITLE)}
    </Typography>
    <TickList
      items={[
        { primaryText: TranslationsProxy.getUiText(SUBSCRIPTION_BP1) },
        { primaryText: TranslationsProxy.getUiText(SUBSCRIPTION_BP2) },
        { primaryText: TranslationsProxy.getUiText(SUBSCRIPTION_BP3) },
      ]}
      dense
    />
  </Grid>
);

const SubscriptionComponent = ({
  subscription, subscriptionIndex, groupIndex, onclick,
}:SubscriptionComponentProps) => (
  <Grid item xs key={`group-${groupIndex}-s-${subscriptionIndex}`}>
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {`${TranslationsProxy.getUiText(`${SUBSCRIPTION_UPDATE_SUBSCRIPTION_KEY}${subscription.updateInterval}`)}`}
        </Typography>
        <Typography variant="h5" component="h2">
          {`${TranslationsProxy.getUiText(SUBSCRIPTION_PRICE_TEXT)} ${subscription.price}${TranslationsProxy.getUiText(`${SUBSCRIPTION_CURRENCY_KEY}${subscription.currency}`)} / ${TranslationsProxy.getUiText(`${SUBSCRIPTION_PERIOD_KEY}${subscription.updateInterval}`)}`}
        </Typography>
        <Typography color="textSecondary">
          {`${TranslationsProxy.getUiText(`${SUBSCRIPTION_FREE_TRIAL_KEY}${subscription.freeTrialDays}`)}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onclick}>{TranslationsProxy.getUiText(SUBSCRIPTION_SUBSCRIBE_BUTTON)}</Button>
      </CardActions>
    </Card>
  </Grid>
);

function SelectSubscriptionUI(props: SelectSubscriptionReduxProps) {
  const { showSubscriptions, getSubscriptions, subscriptions } = props;
  const history = useHistory();
  useEffect(() => {
    if (!showSubscriptions) {
      getSubscriptions();
    }
  }, [showSubscriptions, getSubscriptions]);

  const groupedSubscriptions:SubscriptionsGroup[] = groupSubscriptions(subscriptions);
  return (
    <SubscriptionsContainer>
      <SubscriptionsMainComponent />
      {groupedSubscriptions.map(({ group, groupId }) => (
        <Grid container spacing={3} justify="center" key={`group-${groupId}`}>
          {group.map((subscription, subscriptionIndex) => (
            <SubscriptionComponent
              key={subscription.id}
              subscription={subscription}
              subscriptionIndex={subscriptionIndex}
              groupIndex={groupId}
              onclick={() => {
                setSelectedSubscription(subscription);
                history.push(`pay/${subscription.id}`);
              }}
            />
          ))}
        </Grid>
      ))}
    </SubscriptionsContainer>
  );
}

export default SelectSubscriptionStateWrapper(SelectSubscriptionUI);
