import {
  RefreshTokenRequest,
  RegisterNewUserRequest, ResetPasswordRequest,
  SignInUserRequest,
} from './requestTypes/authentication.requestTypes';
import {
  REGISTER_USER_API_URL,
  SIGNIN_API_URL,
  VALIDATE_REGISTRATION_API_URL,
  RESET_PASSWORD_API_URL,
  GET_SUBSCRIPTIONS_API_ENDPOINT_URL,
  REFRESH_TOKEN_API_URL,
  CREATE_CUSTOMER_API_ENDPOINT_URL,
  CREATE_SUBSCRIPTION_API_ENDPOINT_URL,
} from './api.constants';
import { GenericRequest } from './requestTypes/common.requestTypes';
import { fetchAccessTokenFromLocalStorage } from './localStorageProxy';
import {
  CreateCustomerRequestType,
  CreateSubscriptionRequestType,
  GetSubscriptionsRequestType,
} from './requestTypes/subscriptions.requestTypes';

const postData = (url:string, request?: GenericRequest, errorHandler:(err:any)=>any = () => {}) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (request?.requiresAuthentication) {
    const accessToken = fetchAccessTokenFromLocalStorage();
    headers.append('accessToken', accessToken);
  }
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(request?.data),
  })
    .then((res) => res.json())
    .catch((err) => errorHandler(err));
};

function ApiProxy() {
  return {
    registerNewUserApi(userData: RegisterNewUserRequest) {
      return postData(REGISTER_USER_API_URL, { data: userData });
    },
    validateRegistrationApi(token: string) {
      return postData(VALIDATE_REGISTRATION_API_URL, { data: { token } });
    },
    signInApi(signInUserRequest: SignInUserRequest) {
      return postData(SIGNIN_API_URL, { data: signInUserRequest });
    },
    resetPasswordApi(request: ResetPasswordRequest) {
      return postData(RESET_PASSWORD_API_URL, { data: request });
    },
    refreshTokenApi(request:RefreshTokenRequest) {
      return postData(REFRESH_TOKEN_API_URL, request);
    },
    getSubscriptionsApi(request: GetSubscriptionsRequestType) {
      return postData(GET_SUBSCRIPTIONS_API_ENDPOINT_URL, request);
    },
    createCustomerApi(request: CreateCustomerRequestType) {
      return postData(CREATE_CUSTOMER_API_ENDPOINT_URL, request);
    },
    createSubscriptionApi(request: CreateSubscriptionRequestType) {
      return postData(CREATE_SUBSCRIPTION_API_ENDPOINT_URL, request);
    },
  };
}

export default ApiProxy();
