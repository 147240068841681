import {
  HeaderInfoState,
  RegistrationState,
  ResetPasswordState,
  SignInState,
  ValidateRegistrationState,
} from './authentication-state.interfaces';
import { StatusCodes } from '../services/constants/statusCodes.constants';
import { RequestStatus } from './state.enums';

export const defaultRegistrationState: RegistrationState = {
  registrationResponse: {
    success: false,
    status: StatusCodes.SUCCESS,
  },
  failedRegistrationResponse: { message: '' },
  registrationRequestStatus: {
    status: RequestStatus.SUCCESS,
    message: '',
  },
  inlineError: '',
  showResetYourPasswordMessage: false,
};

export const defaultHeaderInfoState: HeaderInfoState = {
  loadSignInPage: false,
  totalNumberOfCollections: 0,
  totalNumberOfItems: 0,
  isUserAuthenticated: false,
};

export const defaultValidateRegistrationState: ValidateRegistrationState = {
  validateRegistrationResponse: {
    success: false,
    refreshToken: '',
    message: '',
    accessToken: '',
  },
};

export const defaultSignInState: SignInState = {
  loadingDialog: {
    show: false,
    message: '',
  },
  signInFailed: {
    attempt: 0,
    message: '',
    email: '',
  },
  user: {
    permissions: [],
    username: '',
    isAuthenticated: false,
  },
};

export const defaultResetPasswordState : ResetPasswordState = {
  loadingDialog: {
    show: false,
    message: '',
    success: false,
  },
  inlineError: '',
  dialogError: '',
};
