import { takeLatest, put, call } from 'redux-saga/effects';
import {
  RESET_PASSWORD_STEP1_REQUEST_ACTION,
  RESET_PASSWORD_STEP2_REQUEST_ACTION,
  ResetPasswordRequestAction,
} from '../../actions/authentication/resetPassword.actionTypes';
import {
  ResetPasswordResponse,
} from '../../requestTypes/authentication.requestTypes';
import {
  RESET_PASSWORD_REQUEST_IN_PROGRESS_MESSAGE,
  RESET_PASSWORD_STEP2_SUCCESS_MESSAGE,
} from '../../../constants/message.constants';
import {
  resetPasswordFailedAction,
  resetPasswordRequestInProgressAction,
  resetPasswordStep1SuccessfulAction, resetPasswordStep2SuccessfulAction,
} from '../../actions/authentication/resetPassword.actions';
import ApiProxy from '../../apiProxy';
import { WRONG_CLIENT_CONFIGURATION } from '../../../constants/error.constants';

const isStep1Request = (
  action: ResetPasswordRequestAction,
) => action.type === RESET_PASSWORD_STEP1_REQUEST_ACTION;

const isStep2Request = (action: ResetPasswordRequestAction) => !isStep1Request(action)
    && action.type === RESET_PASSWORD_STEP2_REQUEST_ACTION;

function* resetPassword(request:ResetPasswordRequestAction) {
  yield put(resetPasswordRequestInProgressAction(RESET_PASSWORD_REQUEST_IN_PROGRESS_MESSAGE));
  try {
    const response: ResetPasswordResponse = yield call(ApiProxy.resetPasswordApi, request.payload);
    if (response.success && isStep1Request(request)) {
      yield put(resetPasswordStep1SuccessfulAction(response.message));
    } else if (response.success && isStep2Request(request)) {
      yield put(resetPasswordStep2SuccessfulAction(RESET_PASSWORD_STEP2_SUCCESS_MESSAGE));
    } else {
      yield put(resetPasswordFailedAction(response.message));
    }
  } catch (e) {
    yield put(resetPasswordFailedAction(WRONG_CLIENT_CONFIGURATION));
  }
}

export default function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD_STEP1_REQUEST_ACTION, resetPassword);
  yield takeLatest(RESET_PASSWORD_STEP2_REQUEST_ACTION, resetPassword);
}
