import { takeLatest } from 'redux-saga/effects';
import { CREATE_CUSTOMER_REQUEST, CreateCustomerResponse } from '../../actions/subscriptions/createCustomer.actionTypes';
import { CreateCustomerRequestType } from '../../requestTypes/subscriptions.requestTypes';
import { executeAuthenticatedSaga } from '../sagas.utils';
import ApiProxy from '../../apiProxy';
import { createCustomerSuccessfulAction } from '../../actions/subscriptions/createCustomer.actions';

function* createCustomer() {
  const createCustomerRequest:CreateCustomerRequestType = {
    requiresAuthentication: true,
  };

  yield executeAuthenticatedSaga<CreateCustomerResponse>(
    ApiProxy.createCustomerApi,
    createCustomerRequest,
    createCustomerSuccessfulAction,
  );
}

export default function* createCustomerWatcher() {
  yield takeLatest(CREATE_CUSTOMER_REQUEST, createCustomer);
}
