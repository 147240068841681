import DataConversionException from '../exceptions/data.exceptions';

export type DataTypeName = 'string' | 'float' | 'int' | 'object' | 'boolean';

export type DataType = string | number | boolean | object | undefined;

export const convertStringToType = (
  data: string | null,
  desiredDataType: DataTypeName,
): DataType => {
  if (!data) {
    return undefined;
  }
  try {
    switch (desiredDataType) {
      case 'string':
        return String(data);
      case 'float':
        return Number.parseFloat(data);
      case 'int':
        return Number.parseInt(data, 10);
      case 'object':
        return JSON.parse(data);
      case 'boolean':
        return Boolean(data);
      default:
        return data;
    }
  } catch (e) {
    throw new DataConversionException();
  }
};

export const emailIsValid = (email:string):boolean => email !== '' && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export function chunkData<T>(data:T[], chunkSize: number):T[][] {
  const result:T[][] = [];
  for (let i = 0, j = data.length; i < j; i += chunkSize) {
    result.push(data.slice(i, i + chunkSize));
  }
  return result;
}
