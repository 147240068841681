import errors from './errors.json';
import messages from './messages.json';
import uiText from './uiText.json';

const SUPPORTED_LANGUAGES:string[] = ['en'];

type TranslationsMapType = {[msgCode:string]:{[language:string]:string}};

type EasyAccessTranslations = {[msgCode:string]:string};

const grabUserLanguage = ():string => {
  const [language] = window.navigator.language.split('-');
  if (SUPPORTED_LANGUAGES.includes(language)) {
    return language;
  }
  return 'en';
};

const transformTranslationObject = (
  data:TranslationsMapType, language:string,
):EasyAccessTranslations => {
  const keys = Object.keys(data);
  const result:EasyAccessTranslations = {};
  keys.forEach((key) => { result[key] = data[key][language]; });
  return result;
};

const grabMessages = (
  language:string,
):EasyAccessTranslations => transformTranslationObject(messages, language);

const grabErrors = (
  language:string,
):EasyAccessTranslations => transformTranslationObject(errors, language);

const grabUiText = (
  language:string,
):EasyAccessTranslations => transformTranslationObject(uiText, language);

class TranslationsProxy {
  private readonly language:string;

  private readonly messages:EasyAccessTranslations;

  private readonly errors:EasyAccessTranslations;

  private readonly uiText:EasyAccessTranslations;

  private static instance: TranslationsProxy | undefined;

  private constructor() {
    this.language = grabUserLanguage();
    this.messages = grabMessages(this.language);
    this.errors = grabErrors(this.language);
    this.uiText = grabUiText(this.language);
  }

  static get() {
    if (!this.instance) {
      this.instance = new TranslationsProxy();
    }
    return this.instance;
  }

  getMessage = (msgId:string = ''):string => this.messages[msgId];

  getError = (errorId:string):string => this.errors[errorId] || errorId;

  getText = (textId:string = ''):string => {
    const msgSplitArray = textId.split('_');
    const msgType = msgSplitArray.length && msgSplitArray[0];
    if (msgType === 'msg') {
      return this.getMessage(textId);
    }
    if (msgType === 'ui') {
      return this.getUiText(textId);
    }
    return this.getError(textId);
  };

  getUiText = (textId: string = ''):string => this.uiText[textId] || textId;
}

export default TranslationsProxy.get();
