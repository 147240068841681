import {
  VALIDATE_REGISTRATION_SERVICE_FAILED,
  VALIDATE_REGISTRATION_SUCCESSFUL_RESPONSE,
  ValidateRegistrationError,
  ValidateRegistrationServiceAction,
} from '../actions/authentication/validateRegistration.actionTypes';
import { RequestStatus } from '../../state/state.enums';
import {
  RegisterNewUserResponse,
  SignInFailedResponse,
  SignInUserSuccessfulResponse,
  ValidateRegistrationSuccessfulResponse,
} from '../responseTypes/authentication.responseTypes';
import {
  PENDING_USER_REGISTRATION_REQUEST,
  REGISTER_NEW_USER_FAILED,
  REGISTER_NEW_USER_SUCCESSFUL_RESPONSE,
  RegistrationServiceAction,
  RESET_ACCOUNT_ALREADY_EXISTS,
  RESET_INLINE_ERROR,
} from '../actions/authentication/register.actionTypes';
import {
  SIGN_IN_REQUEST_IN_PROGRESS,
  SIGN_IN_USER_FAILED,
  SIGN_IN_USER_SUCCESS,
  SignInServiceAction,
} from '../actions/authentication/signIn.actionTypes';
import { ResetPasswordSuccessfulResponse, SignInRequestInProgress } from '../requestTypes/authentication.requestTypes';
import {
  RESET_PASSWORD_FAILED_ACTION,
  RESET_PASSWORD_REQUEST_IN_PROGRESS_ACTION,
  RESET_PASSWORD_STEP1_SUCCESSFUL_ACTION,
  RESET_PASSWORD_STEP2_SUCCESSFUL_ACTION,
  ResetPasswordAction,
} from '../actions/authentication/resetPassword.actionTypes';
import {
  RESET_PASSWORD_REQUEST_IN_PROGRESS_MESSAGE,
  RESET_PASSWORD_STEP1_SUCCESS_MESSAGE, RESET_PASSWORD_STEP2_SUCCESS_MESSAGE,
} from '../../constants/message.constants';
import { ServiceMessagePayload } from '../actions';
import { EMAIL_NOT_FOUND } from '../../constants/error.constants';
import { GO_TO_SIGNIN_PAGE, HeaderInfoAction } from '../actions/ui/header.actionTypes';
import {
  defaultHeaderInfoState,
  defaultRegistrationState, defaultResetPasswordState, defaultSignInState,
  defaultValidateRegistrationState,
} from '../../state/authentication-state.defaults';

export function registrationReducer(
  state = defaultRegistrationState,
  action: RegistrationServiceAction,
) {
  switch (action.type) {
    case PENDING_USER_REGISTRATION_REQUEST:
      return {
        registrationRequestStatus:
            {
              message: action.payload,
              status: RequestStatus.PENDING,
            },
      };
    case REGISTER_NEW_USER_SUCCESSFUL_RESPONSE:
      return { registrationResponse: action.payload as RegisterNewUserResponse };
    case REGISTER_NEW_USER_FAILED: {
      const data = action.payload as RegisterNewUserResponse;
      const inlineError = data.message;
      return { inlineError };
    }
    case RESET_ACCOUNT_ALREADY_EXISTS:
      return {
        registrationResponse: { status: 1 },
      };
    case RESET_INLINE_ERROR:
      return {
        inlineError: null,
      };
    default:
      return state;
  }
}
export function validateRegistrationReducer(
  state = defaultValidateRegistrationState,
  action: ValidateRegistrationServiceAction,
) {
  if (action.type === VALIDATE_REGISTRATION_SUCCESSFUL_RESPONSE) {
    const result = action.payload as ValidateRegistrationSuccessfulResponse;
    return {
      validateRegistrationResponse: {
        success: true,
        accessToken: result.token,
        refreshToken: result.refreshToken,
      },
    };
  }
  if (action.type === VALIDATE_REGISTRATION_SERVICE_FAILED) {
    const message = action.payload as ValidateRegistrationError;
    return {
      validateRegistrationResponse: {
        success: false,
        message: message && message.message,
      },
    };
  }
  return state;
}

export function headerInfoReducer(state = defaultHeaderInfoState, action:HeaderInfoAction) {
  if (action.type === GO_TO_SIGNIN_PAGE) {
    return {
      loadSignInPage: true,
    };
  }
  return state;
}

export function signInReducer(state = defaultSignInState, action: SignInServiceAction) {
  let payload;
  switch (action.type) {
    case SIGN_IN_REQUEST_IN_PROGRESS:
      payload = action.payload as SignInRequestInProgress;
      return {
        loadingDialog: {
          show: true,
          message: payload.message,
        },
      };
    case SIGN_IN_USER_SUCCESS:
      payload = action.payload as SignInUserSuccessfulResponse;
      return {
        user: {
          username: payload.username,
          permissions: payload.permissions,
          isAuthenticated: true,
        },
      };
    case SIGN_IN_USER_FAILED:
      payload = action.payload as SignInFailedResponse;
      return {
        signInFailed: { ...payload },
      };
    case RESET_INLINE_ERROR:
      return {
        inlineError: null,
      };
    default:
      return state;
  }
}

export function resetPasswordReducer(
  state = defaultResetPasswordState,
  action:ResetPasswordAction,
) {
  let payload;
  const { type } = action;
  switch (type) {
    case RESET_PASSWORD_REQUEST_IN_PROGRESS_ACTION:
      return {
        loadingDialog: {
          show: true,
          message: RESET_PASSWORD_REQUEST_IN_PROGRESS_MESSAGE,
        },
      };
    case RESET_PASSWORD_STEP1_SUCCESSFUL_ACTION: {
      payload = action.payload as ResetPasswordSuccessfulResponse;
      return {
        loadingDialog: {
          show: true,
          message: RESET_PASSWORD_STEP1_SUCCESS_MESSAGE,
        },
      };
    }
    case RESET_PASSWORD_STEP2_SUCCESSFUL_ACTION: {
      payload = action.payload as ResetPasswordSuccessfulResponse;
      return {
        loadingDialog: {
          show: true,
          message: RESET_PASSWORD_STEP2_SUCCESS_MESSAGE,
        },
      };
    }
    case RESET_PASSWORD_FAILED_ACTION: {
      payload = action.payload as ServiceMessagePayload;
      const { message } = payload;
      if (message === EMAIL_NOT_FOUND) {
        return {
          inlineError: message,
        };
      }
      return {
        loadingDialog: {
          show: true,
          message: payload.message,
        },
      };
    }
    case RESET_INLINE_ERROR:
      return {
        inlineError: null,
      };
    default:
      return state;
  }
}
