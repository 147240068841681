export const SUBSCRIPTION_HEADER = 'ui_SubscriptionHeader';
export const SUBSCRIPTION_SUBTITLE = 'ui_SubscriptionSubtitle';
export const SUBSCRIPTION_BP1 = 'ui_SubscriptionBP1';
export const SUBSCRIPTION_BP2 = 'ui_SubscriptionBP2';
export const SUBSCRIPTION_BP3 = 'ui_SubscriptionBP3';
export const SUBSCRIBE_NOW = 'ui_SubscriptionSubscribeNow';
export const SUBSCRIPTION_SUBSCRIBE_BUTTON = 'ui_SubscriptionSubscribeButton';
export const SUBSCRIPTION_CANCELATION_KEY = 'ui_SubscriptionCancelation_';
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION_KEY = 'ui_SubscriptionUpdateInterval_';
export const SUBSCRIPTION_PRICE_TEXT = 'ui_SubscriptionPriceText';
export const SUBSCRIPTION_FREE_TRIAL_KEY = 'ui_SubscriptionFreeTrial_';
export const SUBSCRIPTION_CURRENCY_KEY = 'ui_SubscriptionCurrency_';
export const SUBSCRIPTION_PERIOD_KEY = 'ui_SubscriptionPeriod_';
export const SUBSCRIPTION_TRY_ANOTHER_PAYMENT_METHOD = 'ui_Subscription_TryDifferentPM';
