import { addToLS, fetchFromLS } from '../utlis/localStorage.utils';
import {
  ACCESS_TOKEN_LS_KEY,
  APPLICATION_ID_KEY, LATEST_INVOICE_ID, LATEST_INVOICE_PAYMENT_ITENT_STATUS, PERMISSIONS_LS_KEY,
  REFRESH_TOKEN_LS_KEY, ROLE_LS_KEY, SELECTED_SUBSCRIPTION_LS_KEY,
  USERNAME_LS_KEY,
} from '../constants/localStorage.constants';
// import {PaymentIntentStatus} from "../constants/paymentMethod.constants";
import { Subscription } from '../state/subscriptions-state.interfaces';

export const fetchAccessTokenFromLocalStorage = ():string => fetchFromLS(ACCESS_TOKEN_LS_KEY, 'string') as string;
export const fetchRefreshTokenFromLocalStorage = ():string => fetchFromLS(REFRESH_TOKEN_LS_KEY, 'string') as string;
export const fetchApplicationSessionId = ():string => fetchFromLS(APPLICATION_ID_KEY, 'string') as string;
export const setApplicationSessionId = (sessionId:string) => addToLS(APPLICATION_ID_KEY, sessionId);
export const fetchLatestPaymentIntentStatus = ():string => fetchFromLS(LATEST_INVOICE_PAYMENT_ITENT_STATUS, 'string') as string;
export const fetchLatestInvoiceId = ():string => fetchFromLS(LATEST_INVOICE_ID, 'string') as string;
export const fetchSelectedSubscription = ():Subscription => fetchFromLS(SELECTED_SUBSCRIPTION_LS_KEY, 'object') as Subscription;
export const setAccessToken = (token: string) => addToLS(ACCESS_TOKEN_LS_KEY, token);
export const setRefreshToken = (refreshToken:string) => addToLS(REFRESH_TOKEN_LS_KEY, refreshToken);
export const setPermissions = (
  permissions:string[] = [],
) => addToLS(PERMISSIONS_LS_KEY, permissions);
export const setRoles = (roles:string[] = []) => addToLS(ROLE_LS_KEY, roles);
export const setUsername = (username: string) => addToLS(USERNAME_LS_KEY, username);
export const setSelectedSubscription = (
  subscription: Subscription,
) => addToLS(SELECTED_SUBSCRIPTION_LS_KEY, JSON.stringify(subscription));
