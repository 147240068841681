export const USER_REGISTRATION_SUCCESSFUL = 'msg_0001';
export const FORGOT_YOUR_PASSWORD_QUESTION = 'msg_0002';
export const VALIDATING_REGISTRATION = 'msg_0003';
export const REGISTRATION_WAITING_MESSAGE = 'msg_0004';
export const VALIDATE_REGISTRATION_COMPLETE = 'msg_0006';
export const SIGN_IN_REQUEST_IN_PROGRESS_MESSAGE = 'msg_0007';
export const RESET_PASSWORD_REQUEST_IN_PROGRESS_MESSAGE = 'msg_0009';
export const RESET_PASSWORD_STEP1_SUCCESS_MESSAGE = 'msg_0008';
export const RESET_PASSWORD_STEP2_SUCCESS_MESSAGE = 'msg_0010';
export const SUBSCRIPTION_SUCCESSFUL_PAYMENT_METHOD_AUTHORIZATION = 'msg_0011';
export const SUBSCRIPTION_PAYMENT_METHOD_REQUIRES_AUTHENTICATION = 'msg_0012';
export const SUBSCRIPTION_TRY_DIFFERENT_PAYMENT_METHOD = 'msg_0013';
