export enum Cancel {
  ANYTIME = 'anytime',
}

export enum AccessLevel {
  FULL = 'full',
  NONE = 'none',
}

export enum Currency {
  EUR = 'EUR',
}
