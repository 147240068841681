import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VALIDATE_REGISTRATION_TOKEN_ACTION,
  ValidateRegistrationRequestAction,
} from '../../actions/authentication/validateRegistration.actionTypes';
import {
  RegisterNewUserResponse,
  ValidateRegistrationSuccessfulResponse,
} from '../../responseTypes/authentication.responseTypes';
import ApiProxy from '../../apiProxy';
import { StatusCodes } from '../../constants/statusCodes.constants';
import {
  failedUserRegistrationRequest,
  pendingUserRegistrationRequest,
  successfulUserRegistrationRequest,
} from '../../actions/authentication/register.actions';
import {
  successfulValidateRegistration,
  validateRegistrationFailed,
} from '../../actions/authentication/validateRegistration.actions';
import {
  REGISTER_NEW_USER_REQUEST_ACTION,
  RegisterNewUserAction,
} from '../../actions/authentication/register.actionTypes';
import { SERVICE_UNAVAILABLE, WRONG_CLIENT_CONFIGURATION } from '../../../constants/error.constants';
import { REGISTRATION_WAITING_MESSAGE } from '../../../constants/message.constants';

function* registerNewUser(registerNewUserAction:RegisterNewUserAction) {
  try {
    yield put(pendingUserRegistrationRequest(REGISTRATION_WAITING_MESSAGE));

    const response: RegisterNewUserResponse = yield call(
      ApiProxy.registerNewUserApi,
      registerNewUserAction.payload,
    );

    if (response.success) {
      yield put(successfulUserRegistrationRequest(response));
    } else {
      yield put(failedUserRegistrationRequest(response));
    }
  } catch (e) {
    const failedResponse = {
      success: false,
      message: WRONG_CLIENT_CONFIGURATION,
      statusCode: StatusCodes.SERVICE_ERROR,
    } as RegisterNewUserResponse;
    yield put(failedUserRegistrationRequest(failedResponse));
  }
}

function* validateRegistrationToken(validateRegistrationAction:ValidateRegistrationRequestAction) {
  try {
    const response: ValidateRegistrationSuccessfulResponse = yield call(
      ApiProxy.validateRegistrationApi,
      validateRegistrationAction.payload.token,
    );
    if (response.success) {
      yield put(successfulValidateRegistration(response));
    } else yield put(validateRegistrationFailed(response.message));
  } catch (error) {
    yield put(validateRegistrationFailed(SERVICE_UNAVAILABLE));
  }
}

export default function* registerNewUserWatcher() {
  yield takeLatest(REGISTER_NEW_USER_REQUEST_ACTION, registerNewUser);
  yield takeLatest(VALIDATE_REGISTRATION_TOKEN_ACTION, validateRegistrationToken);
}
