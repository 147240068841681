import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { CreatePaymentMethodData, StripeCardElement } from '@stripe/stripe-js';
import { useHistory, useParams } from 'react-router';
import { PaymentsReduxProps, PaymentsStateWrapper } from './Payments.cc';
import { CreditCardPaymentForm, PaymentMethodAccordion } from '../../ui/Payments';
import LoadingDialog from '../../common/LoadingDialog';
import { PaymentIntentStatus, PaymentMethodType, StripePaymentMethodType } from '../../constants/paymentMethod.constants';
import { fetchLatestPaymentIntentStatus, fetchSelectedSubscription } from '../../services/localStorageProxy';
import { Subscription } from '../../state/subscriptions-state.interfaces';
import SuccessfulSubscriptionDialog from '../../ui/dialogs/SuccessfulSubscriptionDialog';
import TranslationsProxy from '../../translations/translationsProxy';
import { SUBSCRIPTION_TRY_ANOTHER_PAYMENT_METHOD } from '../../constants/uiText.contants';

interface PaymentsUiProps extends PaymentsReduxProps {
}

function Payments(props:PaymentsUiProps) {
  const {
    showLoadingDialog,
    createCustomer, customerIsCreated, customer,
    successfullySubscribed, successfulUserSubscription,
    showPaymentFailureMessage, paymentFailureReason,
  } = props;

  const selectedSubsciption = fetchSelectedSubscription();
  const { subscriptionId = selectedSubsciption?.id } = useParams();
  const [error, setError] = useState<string | null>();
  const [subscriptionError, setSubscriptionError] = useState();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!customerIsCreated) {
      createCustomer();
    }
    if (showPaymentFailureMessage) {
      setSubscriptionError(showPaymentFailureMessage);
    }
  }, [createCustomer, customerIsCreated, setSubscriptionError, showPaymentFailureMessage]);

  if (!stripe || !elements) {
    return <div>STRIPE IS NOT LOADING</div>;
  }

  // Handle real-time validation errors from the card Element.
  const handleChange = (event:any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
      setSubscriptionError(false);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event:any, paymentMethodType: PaymentMethodType) => {
    event.preventDefault();
    const { createSubscription } = props;
    const { id } = customer;
    const customerId = id;
    const selectedSubscription:Subscription = fetchSelectedSubscription();
    const { priceId } = selectedSubscription;
    let paymentCarrier:StripePaymentMethodType = null;
    let paymentMethodRequestData:CreatePaymentMethodData | null = null;
    if (paymentMethodType === PaymentMethodType.CREDIT_CARD) {
      paymentCarrier = elements.getElement(CardElement) as StripeCardElement;
      paymentMethodRequestData = {
        type: 'card',
        card: paymentCarrier,
      };
    }
    if (paymentMethodRequestData === null) {
      return;
    }
    // TODO: deal with error flow
    const { paymentMethod = { id: '' } } = await stripe.createPaymentMethod(paymentMethodRequestData);
    // if (paymentMethod.id === '') {
    // }
    const paymentMethodId = paymentMethod.id;

    const latestInvoicePaymentIntentStatus = fetchLatestPaymentIntentStatus();
    if (latestInvoicePaymentIntentStatus === PaymentIntentStatus.REQUIRES_PAYMENT_METHOD) {
      // const invoiceId = fetchLatestInvoiceId();
      // if(invoiceId){
      //     retryInvoiceWithNewPaymentMethod({customerId, paymentMethodId,invoiceId,priceId});
      // }else{
      createSubscription({
        requiresAuthentication: true, paymentMethodId, priceId, customerId, subscriptionId,
      });
      // }
    } else if (!latestInvoicePaymentIntentStatus) {
      createSubscription({
        requiresAuthentication: true, paymentMethodId, priceId, customerId, subscriptionId,
      });
    }
  };

  if (successfullySubscribed) {
    return (
      <SuccessfulSubscriptionDialog
        cancel={successfulUserSubscription.cancel}
        currency={successfulUserSubscription.currency}
        updateInterval={successfulUserSubscription.updateInterval}
        accessLevel={successfulUserSubscription.accessLevel}
        freeTrialDays={successfulUserSubscription.freeTrialDays}
        price={successfulUserSubscription.price}
        freeTrialEndDate={successfulUserSubscription.freeTrialEndDate}
        subscriptionPeriodEnd={successfulUserSubscription.subscriptionPeriodEnd}
        countDown={30}
        callback={() => history.push('/home')}
      />
    );
  }

  if (showLoadingDialog) {
    return <LoadingDialog alertDialog messageCode="Hamster is working..." />;
  }

  if (!customerIsCreated) {
    return <LoadingDialog alertDialog messageCode="Hamster is Working..." />;
  }

  return (
    <Container maxWidth="md">
      <h1>Select Payment Method</h1>
      { subscriptionError
                && (
                <div>
                  <p>{TranslationsProxy.getText(paymentFailureReason)}</p>
                  <p>{TranslationsProxy.getUiText(SUBSCRIPTION_TRY_ANOTHER_PAYMENT_METHOD)}</p>
                </div>
                )}
      <PaymentMethodAccordion
        title="Credit/Debit Card"
        childComponent={(
          <CreditCardPaymentForm
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
          />
                  )}
      />
    </Container>

  );
}

export default PaymentsStateWrapper(Payments);
