import {
  Avatar, Button, Container, CssBaseline, TextField, Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory, useParams } from 'react-router';
import useCommonStyles from './authentication.styles';
import { emailIsValid } from '../../utlis/data.utils';
import TranslationsProxy from '../../translations/translationsProxy';
import { ResetPasswordReduxProps, ResetPasswordStateWrapper } from './ResetPassword.cc';
import { APPLICATION_NAME } from '../../constants/application.constants';
import LoadingDialog from '../../common/LoadingDialog';
import {
  ResetPasswordStep1Request,
  ResetPasswordStep2Request,
} from '../../services/requestTypes/authentication.requestTypes';
import { RESET_PASSWORD_STEP2_SUCCESS_MESSAGE } from '../../constants/message.constants';
import { EXPIRED_RESET_PASSWORD_REQUEST } from '../../constants/error.constants';
import CountDownLoadingDialog from '../../common/CountDownLoadingDialog';

const InlineError = styled.div`
    color: red;
`;

export interface ResetPasswordUIProps extends ResetPasswordReduxProps{
  step: number
}

function RedirectToSignInMessage() {
  return <div><a href="/SignIn">You will be automatically redirected to SignIn</a></div>;
}

function RedirectToResetPasswordMessage() {
  return <div><a href="/resetPassword/step1">You will be automatically redirected to Reset Password</a></div>;
}

function ResetPasswordUI(props: ResetPasswordUIProps) {
  const classes = useCommonStyles();

  const { resetPasswordToken } = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    email: '',
    isButtonDisabled: true,
    password: '',
    confirmPassword: '',
    isPasswordValid: false,
  });

  const validateEmail = useCallback((e) => {
    const isEmailValid = emailIsValid(e.target.value);
    props.resetInlineError();
    setState({
      ...state,
      email: (isEmailValid) ? e.target.value : '',
      isButtonDisabled: !isEmailValid,
    });
  }, [state, props]);

  const setPassword = useCallback((e:{target:{value:string}}) => {
    const password = e.target.value;
    const isPasswordValid = state.confirmPassword === password;
    setState({
      ...state,
      password,
      isButtonDisabled: !password || !isPasswordValid,
    });
  }, [state]);

  const validatePassword = useCallback((e) => {
    const confirmPassword = e.target.value;
    const isPasswordValid = Boolean(state.password) && state.password === confirmPassword;
    setState({
      ...state,
      isPasswordValid,
      confirmPassword,
      isButtonDisabled: !isPasswordValid,
    });
  }, [state]);

  const onClickSubmitButton = useCallback(() => {
    const request: ResetPasswordStep1Request = {
      application: APPLICATION_NAME,
      username: state.email,
      email: state.email,
    };
    props.submitResetPasswordRequest(request);
  }, [state, props]);

  const onClickNewPasswordButton = useCallback(() => {
    const request: ResetPasswordStep2Request = {
      token: resetPasswordToken,
      newPassword: state.confirmPassword,
    };
    props.submitNewPasswordRequest(request);
  }, [state, props, resetPasswordToken]);

  const { loadingDialog } = props;
  if (
    loadingDialog
      && loadingDialog.show
      && loadingDialog.message === RESET_PASSWORD_STEP2_SUCCESS_MESSAGE
  ) {
    const selfDestructionSettings = {
      timeOut: 20,
      callback: () => history.push('/signIn'),
    };
    return (
      <CountDownLoadingDialog
        messageComponent={RedirectToSignInMessage}
        alertDialog
        selfDestructionSettings={selfDestructionSettings}
      />
    );
  }

  if (
    loadingDialog
      && loadingDialog.show
      && loadingDialog.message === EXPIRED_RESET_PASSWORD_REQUEST
  ) {
    const selfDestructionSettings = {
      timeOut: 5,
      callback: () => history.push('/resetPassword/step1'),
    };
    return (
      <CountDownLoadingDialog
        messageComponent={RedirectToResetPasswordMessage}
        alertDialog
        selfDestructionSettings={selfDestructionSettings}
      />
    );
  }

  if (loadingDialog && loadingDialog.show) {
    return (
      <LoadingDialog messageCode={loadingDialog.message} alertDialog />
    );
  }

  const { inlineError, step } = props;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {inlineError
                    && (
                    <InlineError>
                      {TranslationsProxy.getError(inlineError)}
                    </InlineError>
                    )}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {step === 1
                    && (
                    <div>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        helperText="Enter your Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={validateEmail}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={Boolean(inlineError) || state.isButtonDisabled}
                        onClick={onClickSubmitButton}
                      >
                        Request to Reset my Password
                      </Button>
                    </div>
                    )}
        {
                    step === 2
                    && (
                    <div>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={setPassword}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="ConfirmPassword"
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                        onChange={validatePassword}
                        error={false}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={Boolean(inlineError) || state.isButtonDisabled}
                        onClick={onClickNewPasswordButton}
                      >
                        Confirm new password
                      </Button>
                    </div>
                    )
                }
      </div>
    </Container>
  );
}

export default ResetPasswordStateWrapper(ResetPasswordUI);
