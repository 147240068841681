import { convertStringToType, DataType, DataTypeName } from './data.utils';

export const fetchFromLS = (key: string, desiredDataType:DataTypeName): DataType => {
  const data = window.localStorage.getItem(key);
  return convertStringToType(data, desiredDataType);
};

export const addToLS = (key: string, data: DataType) => {
  if (typeof data === 'object') {
    window.localStorage.setItem(key, JSON.stringify(data));
  } else {
    window.localStorage.setItem(key, String(data));
  }
};
