import { all } from 'redux-saga/effects';
import registerNewUserWatcher from './register.saga';
import signInUserWatcher from './signIn.saga';
import resetPasswordWatcher from './resetPassword.saga';

export default function* authenticationRootWatcher() {
  yield all([
    registerNewUserWatcher(),
    resetPasswordWatcher(),
    signInUserWatcher(),
  ]);
}
