import React, { useCallback, useState } from 'react';
import {
  Avatar, Box, Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { SignInReduxProps, SignInStateWrapper } from './SignIn.cc';
import { emailIsValid } from '../../utlis/data.utils';
import { APPLICATION_NAME } from '../../constants/application.constants';
import { SignInUserRequest } from '../../services/requestTypes/authentication.requestTypes';
import { fetchApplicationSessionId } from '../../services/localStorageProxy';
import TranslationsProxy from '../../translations/translationsProxy';
import LoadingDialog from '../../common/LoadingDialog';
import { FORGOT_YOUR_PASSWORD_QUESTION } from '../../constants/message.constants';
import { INCORRECT_PASSWORD } from '../../constants/error.constants';
import Copyright from '../../common/Copyright.ui';
import useCommonStyles from './authentication.styles';

interface SignInUIProps extends SignInReduxProps{
}

const InlineError = styled.div`
    color: red;
`;

let attempts = 0;

function SignInUI(props:SignInUIProps) {
  const history = useHistory();
  const classes = useCommonStyles();
  const { triedEmail } = props;

  const [state, setState] = useState({
    email: triedEmail || '',
    password: '',
    signInButtonDisabled: true,
  });

  const validateEmail = useCallback((e) => {
    props.resetInlineError();
    const isEmailValid = emailIsValid(e.target.value);
    setState({
      ...state,
      email: (isEmailValid) ? e.target.value : '',
      signInButtonDisabled: !isEmailValid || !state.password,
    });
  },
  [state, props]);

  const changePassword = useCallback((e:{target:{value:string}}) => {
    const password = e.target.value;
    props.resetInlineError();
    const isEmailValid = emailIsValid(state.email);
    setState({
      ...state,
      password,
      signInButtonDisabled: !isEmailValid || !password,
    });
  }, [state, props]);

  const signInButtonClick = useCallback(() => {
    const userSignInRequest:SignInUserRequest = {
      application: APPLICATION_NAME,
      deviceId: fetchApplicationSessionId(),
      username: state.email,
      password: state.password,
      attempt: attempts,
    };
    props.signIn(userSignInRequest);
    setState({ ...state, password: '' });
  }, [state, props]);

  const {
    loadingDialog, user, failedAttempts, inlineError,
  } = props;

  if (failedAttempts > 0 && inlineError === INCORRECT_PASSWORD) {
    attempts = props.failedAttempts;
  }

  if (loadingDialog && loadingDialog.show) {
    return <LoadingDialog messageCode={loadingDialog.message} alertDialog />;
  }

  if (user && user.isAuthenticated) {
    history.push('/subscriptions');
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        {inlineError && attempts <= 3
                    && <InlineError>{TranslationsProxy.getError(inlineError)}</InlineError>}

        {inlineError && attempts > 3
                    && (
                    <InlineError>
                      <a href="/resetPassword/step1">{TranslationsProxy.getMessage(FORGOT_YOUR_PASSWORD_QUESTION)}</a>
                    </InlineError>
                    )}

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          defaultValue={triedEmail || ''}
          onChange={validateEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          defaultValue=""
          autoComplete="current-password"
          onChange={changePassword}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={state.signInButtonDisabled || Boolean(inlineError)}
          onClick={signInButtonClick}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/resetPassword/step1" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signUp" variant="body2">
              Don&apos;t have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignInStateWrapper(SignInUI);
