import { ServiceAction } from '../index';
import { Subscription } from '../../../state/subscriptions-state.interfaces';

export const GET_SUBSCRIPTIONS_REQUEST_IN_PROGRESS = 'Getting Subscriptions in Progress';
export const GET_SUBSCRIPTIONS_REQUEST = 'Request Available Subscriptions';
export const GET_SUBSCRIPTIONS_SUCCESSFUL = 'Succesful GetSubscriptions Response';
export type GetSubscriptionsInProgress = {
  message: string,
};

export interface GetSubscriptionsResponse {
  subscriptions: Subscription[],
  userIsAlreadySubscribed: boolean,
  success?: boolean,
  message?: string,
}

export type GetSubscriptionsInProgressAction = ServiceAction<GetSubscriptionsInProgress>;
export type GetSubscriptionsAction = ServiceAction<string>;
export type GetSubscriptionsSuccesfulAction = ServiceAction<GetSubscriptionsResponse>;
