import { APPLICATION_NAME } from './application.constants';

export const ACCESS_TOKEN_LS_KEY = `${APPLICATION_NAME}_AT`;
export const LATEST_INVOICE_PAYMENT_ITENT_STATUS = `${APPLICATION_NAME}_LIPIS`;
export const LATEST_INVOICE_ID = `${APPLICATION_NAME}/LII`;
export const APPLICATION_ID_KEY = `${APPLICATION_NAME}_DEV_ID`;
export const REFRESH_TOKEN_LS_KEY = `${APPLICATION_NAME}_RT`;
export const USERNAME_LS_KEY = `${APPLICATION_NAME}_UN`;
export const ROLE_LS_KEY = `${APPLICATION_NAME}_R`;
export const PERMISSIONS_LS_KEY = `${APPLICATION_NAME}_P`;
export const SELECTED_SUBSCRIPTION_LS_KEY = `${APPLICATION_NAME}_SS`;
