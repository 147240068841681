import {
  GET_SUBSCRIPTIONS_SUCCESSFUL, GetSubscriptionsResponse,
  GetSubscriptionsSuccesfulAction,
} from '../actions/subscriptions/getSubscriptions.actionTypes';
import {
  CREATE_CUSTOMER_SUCCESSFUL_ACTION,
  CreateCustomerResponse,
  PaymentsAction,
} from '../actions/subscriptions/createCustomer.actionTypes';
import {
  PENDING_SUBSCRIPTION_REQUEST_ACTION, SUBSCRIPTION_CREATION_FAILED_ACTION,
  SUCCESSFUL_SUBSCRIPTION_ACTION,
} from '../actions/subscriptions/createSubscription.actions';
import { PaymentsUIState, UserSubscription } from '../../state/subscriptions-state.interfaces';
import { ServiceMessagePayload } from '../actions';

export function getSubscriptionsReducer(state = {}, action:GetSubscriptionsSuccesfulAction) {
  const { type } = action;
  const { payload } = action;
  if (type === GET_SUBSCRIPTIONS_SUCCESSFUL) {
    const {
      subscriptions,
      userIsAlreadySubscribed,
    } = payload as GetSubscriptionsResponse;
    return {
      subscriptions,
      userIsAlreadySubscribed,
    };
  }
  return state;
}

export function paymentsReducer(state = {}, action: PaymentsAction): Partial<PaymentsUIState> {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CUSTOMER_SUCCESSFUL_ACTION: {
      const { customer } = payload as CreateCustomerResponse;
      return { customer, customerIsCreated: true };
    }
    case PENDING_SUBSCRIPTION_REQUEST_ACTION:
      return {
        ...state,
        pendingSubscriptionRequest: true,
        paymentFailureReason: '',
      };
    case SUCCESSFUL_SUBSCRIPTION_ACTION:
      return {
        ...state,
        successfullySubscribed: true,
        successfulUserSubscription: {
          ...payload as UserSubscription,
        },
      };
    case SUBSCRIPTION_CREATION_FAILED_ACTION: {
      const { message } = payload as ServiceMessagePayload;
      return {
        ...state,
        paymentFailureReason: message,
        pendingSubscriptionRequest: false,
      };
    }
    default:
      return state;
  }
}
