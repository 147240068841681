import { connect, ConnectedProps } from 'react-redux';
import resetInlineError from '../../services/actions/authentication/authentication.actions';
import { State } from '../../state/authentication-state.interfaces';
import { defaultResetPasswordState } from '../../state/authentication-state.defaults';
import {
  ResetPasswordStep1Request,
  ResetPasswordStep2Request,
} from '../../services/requestTypes/authentication.requestTypes';
import { ResetPasswordStep1RequestAction, ResetPasswordStep2RequestAction } from '../../services/actions/authentication/resetPassword.actionTypes';
import { resetPasswordStep1Request, resetPasswordStep2Request } from '../../services/actions/authentication/resetPassword.actions';

const mapState = (state: State) => {
  const { resetPasswordState = defaultResetPasswordState } = state;

  const {
    inlineError,
    dialogError,
  } = resetPasswordState;

  return {
    loadingDialog: { ...resetPasswordState.loadingDialog },
    inlineError,
    dialogError,
  };
};

const mapDispatch = {
  submitResetPasswordRequest: (
    request: ResetPasswordStep1Request,
  ):ResetPasswordStep1RequestAction => resetPasswordStep1Request(request),
  submitNewPasswordRequest: (
    request: ResetPasswordStep2Request,
  ):ResetPasswordStep2RequestAction => resetPasswordStep2Request(request),
  resetInlineError: () => resetInlineError(),
};

export const ResetPasswordStateWrapper = connect(
  mapState,
  mapDispatch,
);
export type ResetPasswordReduxProps = ConnectedProps<typeof ResetPasswordStateWrapper>;
