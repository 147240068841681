import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';
import { PaymentMethodType } from '../constants/paymentMethod.constants';

const ERROR_TEXT_COLOR = '#fa755a';
const PLACEHOLDER_COLOR = '#aab7c4';

export const PaymentMethodAccordion = (props:{childComponent: React.ReactNode, title:string}) => {
  const { childComponent, title } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {childComponent}
      </AccordionDetails>
    </Accordion>
  );
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: PLACEHOLDER_COLOR,
      },
    },
    invalid: {
      color: ERROR_TEXT_COLOR,
      iconColor: '#fa755a',
    },
  },
};

const PaymentMethodFormStyled = styled.form`
    position: relative;
    width: 100%;
`;

const ErrorStyled = styled(Grid)`
    color: ${ERROR_TEXT_COLOR};
`;

type FormEvent = React.FormEvent<HTMLFormElement>;

interface PaymentMethodComponentInterface {
  handleChange:(event:any)=>void
  handleSubmit:(event:FormEvent, paymentMethodType: PaymentMethodType)=>Promise<void>
  error: string | undefined | null
}

export const CreditCardPaymentForm = (props:PaymentMethodComponentInterface) => {
  const { error, handleChange, handleSubmit } = props;

  const onSubmitFunction = (
    event:FormEvent,
  ) => handleSubmit(event, PaymentMethodType.CREDIT_CARD);

  return (
    <PaymentMethodFormStyled onSubmit={onSubmitFunction}>
      <Grid container direction="column">
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item xs={9}>
            <CardElement
              id="card-element"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <button type="submit">Submit Payment</button>
          </Grid>
          <ErrorStyled item xs={3}>{error}</ErrorStyled>
        </Grid>
      </Grid>

    </PaymentMethodFormStyled>
  );
};

export const PaymentMethodContainer = (props:{childComponent: React.ReactNode, title:string}) => {
  const { childComponent, title } = props;
  return (
    <Paper elevation={3}>
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs={3}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={9}>
          {childComponent}
        </Grid>
      </Grid>
    </Paper>
  );
};
