import React from 'react';
import { UserSubscription } from '../../state/subscriptions-state.interfaces';
import CountDownLoadingDialog from '../../common/CountDownLoadingDialog';
import TranslationsProxy from '../../translations/translationsProxy';
import { convertToDateString } from '../../utlis/date.utils';

const createSubscriptionMessageString = (
  {
    price,
    currency,
    updateInterval,
  }: Partial<UserSubscription>,
):string => {
  const freeTrialEndingString = TranslationsProxy.getText('ui_Subscription_FreeTrialEnding');
  const updateIntervalString = TranslationsProxy.getText(`ui_Subscription_Every_${updateInterval}`);
  return `${freeTrialEndingString} ${price} ${currency} ${updateIntervalString}`;
};

const SuccessfulSubscriptionMessageComponent = (
  {
    accessLevel,
    freeTrialDays,
    price,
    currency,
    freeTrialEndDate,
    cancel,
    updateInterval,
  }: UserSubscription,
) => {
  const successMessage = TranslationsProxy.getText('ui_Subscription_SuccessMessage');

  const accessLevelString = TranslationsProxy.getText(`ui_Subscription_EnjoyAccess_${accessLevel as string}`);

  const freeTrialDaysString = TranslationsProxy.getText(`ui_Subscription_NextDays_${freeTrialDays}`);

  const cancelString = TranslationsProxy.getText(`ui_Subscription_CancelFreeOfCharge_${cancel}`);

  const freeTrialEndDateString = convertToDateString(freeTrialEndDate);

  const endOfFreeTrialMessageString = ` ${TranslationsProxy.getText('ui_Subscription_EndOfFreeTrial')} ${freeTrialEndDateString}`;
  return (
    <div>
      <p>
        {` ${successMessage}`}
      </p>
      <p>
        {` ${accessLevelString} ${freeTrialDaysString}`}
      </p>
      <p>
        {` ${cancelString}`}
      </p>
      <p>
        {createSubscriptionMessageString({ price, currency, updateInterval })}
      </p>
      <p>
        {endOfFreeTrialMessageString}
      </p>
      <a href="/home">Continue</a>
    </div>
  );
};

const SuccessfulSubscriptionDialog = (
  props: UserSubscription & {countDown: number, callback:()=>void},
) => {
  const { countDown: timeOut, callback } = props;
  return (
    <CountDownLoadingDialog
      alertDialog
      messageComponent={
            SuccessfulSubscriptionMessageComponent
        }
      selfDestructionSettings={{ timeOut, callback }}
      messageComponentData={props}
    />
  );
};

export default SuccessfulSubscriptionDialog;
