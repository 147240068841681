import {
  VALIDATE_REGISTRATION_SERVICE_FAILED,
  VALIDATE_REGISTRATION_SUCCESSFUL_RESPONSE,
  VALIDATE_REGISTRATION_TOKEN_ACTION,
  ValidateRegistrationSuccessfulResponseAction,
} from './validateRegistration.actionTypes';
import { ValidateRegistrationSuccessfulResponse } from '../../responseTypes/authentication.responseTypes';

export const validateRegistrationTokenAction = (token: string) => ({
  type: VALIDATE_REGISTRATION_TOKEN_ACTION,
  payload: { token },
});

export const successfulValidateRegistration = (
  validateRegistrationSuccessfulResponse: ValidateRegistrationSuccessfulResponse,
):ValidateRegistrationSuccessfulResponseAction => ({
  type: VALIDATE_REGISTRATION_SUCCESSFUL_RESPONSE,
  payload: validateRegistrationSuccessfulResponse,
});

export const validateRegistrationFailed = (message:string) => ({
  type: VALIDATE_REGISTRATION_SERVICE_FAILED,
  payload: { message },
});
