const AUTHENTICATION_API_URL = process.env.REACT_APP_AUTHENTICATION_API;
export const REGISTER_USER_API_URL = `${AUTHENTICATION_API_URL}/register`;
export const SIGNIN_API_URL = `${AUTHENTICATION_API_URL}/signIn`;
export const VALIDATE_REGISTRATION_API_URL = `${AUTHENTICATION_API_URL}/validateRegistration`;
export const RESET_PASSWORD_API_URL = `${AUTHENTICATION_API_URL}/resetPassword`;
export const REFRESH_TOKEN_API_URL = `${AUTHENTICATION_API_URL}/refreshToken`;

const SUBSCRIPTIONS_API_URL = process.env.REACT_APP_SUBSCRIPTIONS_API;
export const GET_SUBSCRIPTIONS_API_ENDPOINT_URL = `${SUBSCRIPTIONS_API_URL}/getSubscriptions`;
export const CREATE_CUSTOMER_API_ENDPOINT_URL = `${SUBSCRIPTIONS_API_URL}/createCustomer`;
export const CREATE_SUBSCRIPTION_API_ENDPOINT_URL = `${SUBSCRIPTIONS_API_URL}/createSubscription`;
