import { all } from 'redux-saga/effects';
import authenticationRootWatcher from './authentication/authentication.saga';
import subscriptionsRootWatcher from './subscriptions/subscriptions.saga';

export default function* rootSaga() {
  yield all([
    authenticationRootWatcher(),
    subscriptionsRootWatcher(),
  ]);
}
