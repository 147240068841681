import { put, takeLatest, call } from 'redux-saga/effects';
import * as Stripe from '@stripe/stripe-js';
import {
  CREATE_SUBSCRIPTION_REQUEST_ACTION,
  createSubscriptionFailedAction, pendingSubscriptionRequestAction,
  processSuccessfulSubscriptionResponseAction,
  PROCESS_SUCCESSFUL_SUBSCRIPTION_RESPONSE,
  successfulSubscriptionAction,
} from '../../actions/subscriptions/createSubscription.actions';
import { CreateSubscriptionRequestType } from '../../requestTypes/subscriptions.requestTypes';
import ApiProxy from '../../apiProxy';
import { executeAuthenticatedSaga } from '../sagas.utils';
import { CreateSubscriptionResponse, SuccessfulSubscriptionResponse } from '../../responseTypes/subscriptions.responseTypes';
import { ServiceAction } from '../../actions';
import { SubscriptionStatus } from '../../constants/SubscriptionStatus.enum';

type SubscriptionResponseAction = ServiceAction<CreateSubscriptionResponse>;

function* createSubscription(request: ServiceAction<CreateSubscriptionRequestType>) {
  yield put(pendingSubscriptionRequestAction());
  yield executeAuthenticatedSaga(
    ApiProxy.createSubscriptionApi,
    { data: request.payload, requiresAuthentication: true },
    processSuccessfulSubscriptionResponseAction,
  );
}

function* handlePaymentThatRequiresCustomerAction(payment:CreateSubscriptionResponse) {
  const stripe = yield call(Stripe.loadStripe, `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
  const { clientSecret, pendingSetupIntent } = payment;

  /* eslint @typescript-eslint/naming-convention: 0 */
  const { payment_method } = pendingSetupIntent;
  if (!stripe || !clientSecret) {
    throw new Error();
  }
  // if(
  // isRetry &&
  // paymentIntent &&
  // (
  // paymentIntent.status === 'requires_action' ||
  // paymentIntent.status === 'requires_payment_method'
  // )
  // ){
  // try{
  const result = yield call(stripe.confirmCardSetup, clientSecret, {
    payment_method,
  });
    // .then((result) => {
  if (result.error) {
    // Start code flow to handle updating the payment details.
    // Display error message in your UI.
    // The card was declined (i.e. insufficient funds, card has expired, etc).

    // yield put(createSubscriptionFailedAction("Please try a different payment method");

    throw new Error();
  }
  if (result.setupIntent.status === 'succeeded') {
    // Show a success message to your customer.
    // There's a risk of the customer closing the window before the callback.
    // We recommend setting up webhook endpoints later in this guide.
    return yield put(successfulSubscriptionAction(payment as SuccessfulSubscriptionResponse));
  }
  return { success: false };
}

function* handleRequiresPaymentMethod(/* response: CreateSubscriptionResponse */) {
  // const {subscription, priceId, paymentMethodId} = response;
  // if (
  //     subscription.latest_invoice.payment_intent.status ===
  //     'requires_payment_method'
  // ) {
  //     // Using localStorage to manage the state of the retry here,
  //     // feel free to replace with what you prefer.
  //     // Store the latest invoice ID and status.
  //     localStorage.setItem('latestInvoiceId', subscription.latest_invoice.payment_intent.id);
  //     localStorage.setItem(
  //         'latestInvoicePaymentIntentStatus',
  //         subscription.latest_invoice.payment_intent.status
  //     );
  //     throw new Error('card was declined');
  // } else {
  //     return { subscription, priceId, paymentMethodId, success:true };
  // }
}

function* processNonAuthenticatedResponse(payload: CreateSubscriptionResponse) {
  if (payload.status === SubscriptionStatus.ACTIVE || SubscriptionStatus.TRIALING) {
    const successfulSubscriptionResponsePayload = payload as SuccessfulSubscriptionResponse;
    return yield put(successfulSubscriptionAction(successfulSubscriptionResponsePayload));
  }
  return yield put(createSubscriptionFailedAction(''));
}

function* proccessCreateSubscriptionResponse(responseServiceAction: SubscriptionResponseAction) {
  const { payload } = responseServiceAction;
  if (payload.error) {
    // console.log('yield put(createSubscriptionFailedAction("Try a different payment method")');
  }

  try {
    const { pendingSetupIntent } = payload as CreateSubscriptionResponse;
    // handle
    switch (pendingSetupIntent?.status) {
      case 'requires_action':
        return yield handlePaymentThatRequiresCustomerAction(payload);
      case 'requires_payment_method':
        return yield handleRequiresPaymentMethod();
      default:
        return yield processNonAuthenticatedResponse(payload);
    }
  } catch (e) {
    return yield put(createSubscriptionFailedAction('Try a different payment method'));
  }
}

export default function* createSubscriptionWatcher() {
  yield takeLatest(CREATE_SUBSCRIPTION_REQUEST_ACTION, createSubscription);
  yield takeLatest(PROCESS_SUCCESSFUL_SUBSCRIPTION_RESPONSE, proccessCreateSubscriptionResponse);
}
