import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import LoadingDialog from '../../common/LoadingDialog';
import { ValidateRegistrationStateWrapper, ValidateRegistrationReduxProps } from './ValidateRegistration.cc';
import { VALIDATING_REGISTRATION } from '../../constants/message.constants';

function ValidateRegistrationUI(props: ValidateRegistrationReduxProps) {
  const { validationToken } = useParams();
  const history = useHistory();

  useEffect(() => {
    props.validateRegistrationToken(validationToken);
  }, [props, validationToken]);

  const { validateRegistrationComplete, message } = props;

  if (validateRegistrationComplete) {
    history.push('/subscribe');
  }
  return (<LoadingDialog alertDialog messageCode={message || VALIDATING_REGISTRATION} />);
}

export default ValidateRegistrationStateWrapper(ValidateRegistrationUI);
