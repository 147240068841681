import {
  ResetPasswordStep1Request,
  ResetPasswordRequestInProgress,
  ResetPasswordSuccessfulResponse, ResetPasswordStep2Request,
} from '../../requestTypes/authentication.requestTypes';
import { ServiceAction, ServiceMessagePayload } from '../index';

export const RESET_PASSWORD_REQUEST_IN_PROGRESS_ACTION = 'ResetPassword Request in Progress';
export const RESET_PASSWORD_STEP1_SUCCESSFUL_ACTION = 'ResetPassword Step 1 Success';
export const RESET_PASSWORD_STEP2_SUCCESSFUL_ACTION = 'ResetPassword Completed';
export const RESET_PASSWORD_STEP1_REQUEST_ACTION = 'User sends a request to reset password';
export const RESET_PASSWORD_STEP2_REQUEST_ACTION = 'User completes reset password';
export const RESET_PASSWORD_FAILED_ACTION = 'ResetPassword Failed';

export type ResetPasswordRequestInProgressAction = ServiceAction<ResetPasswordRequestInProgress>;
export type ResetPasswordStep1SuccessfulAction = ServiceAction<ResetPasswordSuccessfulResponse>;
export type ResetPasswordStep2SuccessfulAction = ServiceAction<ServiceMessagePayload>;
export type ResetPasswordFailedAction = ServiceAction<ServiceMessagePayload>;
export type ResetPasswordStep1RequestAction = ServiceAction<ResetPasswordStep1Request>;
export type ResetPasswordStep2RequestAction = ServiceAction<ResetPasswordStep2Request>;

export type ResetPasswordRequestAction =
    ResetPasswordStep1RequestAction |
    ResetPasswordStep2RequestAction;

export type ResetPasswordAction =
    ResetPasswordStep1RequestAction |
    ResetPasswordRequestInProgressAction |
    ResetPasswordStep1SuccessfulAction |
    ResetPasswordFailedAction;
