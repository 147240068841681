import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../state/authentication-state.interfaces';
import { defaultValidateRegistrationState } from '../../state/authentication-state.defaults';
import { setAccessToken, setRefreshToken } from '../../services/localStorageProxy';
import { validateRegistrationTokenAction } from '../../services/actions/authentication/validateRegistration.actions';

const mapState = (state: State) => {
  const { validateRegistrationState = defaultValidateRegistrationState } = state;

  const { validateRegistrationResponse } = validateRegistrationState;
  const { message = '' } = validateRegistrationResponse;
  if (validateRegistrationResponse.success) {
    const { accessToken = '', refreshToken = '' } = validateRegistrationResponse;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    return {
      validateRegistrationComplete: true,
      message,
    };
  }
  return {
    validateRegistrationComplete: false,
    message,
  };
};

const mapDispatch = {
  validateRegistrationToken: (
    token: string,
  ) => validateRegistrationTokenAction(token),
};

export const ValidateRegistrationStateWrapper = connect(
  mapState,
  mapDispatch,
);

export type ValidateRegistrationReduxProps =
    ConnectedProps<typeof ValidateRegistrationStateWrapper>;
