import { StripeCardElement, StripeIbanElement } from '@stripe/stripe-js';

export enum PaymentMethodType {
  CREDIT_CARD='card',
  DEBIT_CARD=1,
  PAYPAL,
  IDEAL,
}

export type StripePaymentMethodType = StripeCardElement | StripeIbanElement | null;

export type PaymentMethodRequestType = {
  type: PaymentMethodType,
  card: StripePaymentMethodType
};

export enum PaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  UNKNOWN = 'unknown',
}
