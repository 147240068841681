import { ServiceAction } from '../index';
import { SignInRequestInProgress, SignInUserRequest } from '../../requestTypes/authentication.requestTypes';
import { SignInUserSuccessfulResponse, SignInFailedResponse } from '../../responseTypes/authentication.responseTypes';

export const SIGN_IN_REQUEST_IN_PROGRESS = 'Sign In Request in progress';

export const SIGN_IN_USER_REQUEST = 'Request User SignIn';
export const SIGN_IN_USER_SUCCESS = 'Successful user SignIn';
export const SIGN_IN_USER_FAILED = 'Failed User SignIn';
export type SignInUserRequestAction = ServiceAction<SignInUserRequest>;
export type SignInRequestInProgressAction = ServiceAction<SignInRequestInProgress>;
export type SignInSuccessfulAction = ServiceAction<SignInUserSuccessfulResponse>;
export type SignInFailedAction = ServiceAction<SignInFailedResponse>;

export type SignInServiceAction =
    SignInUserRequestAction |
    SignInRequestInProgressAction |
    SignInSuccessfulAction |
    SignInFailedAction;
