import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import reducers from './services/reducers';
import { APPLICATION_NAME } from './constants/application.constants';
import { fetchApplicationSessionId } from './services/localStorageProxy';
import { createApplicationSessionId } from './utlis/Application.utils';
import errors from './translations/errors.json';
import messages from './translations/messages.json';

export interface AppplicationWindow extends Window {
  chomiko?:{
    sessionId: string,
    errors: {[language:string]:{[error_code:string]:string}},
    messages: {[language:string]:{[msg_code:string]:string}}
  }
}

const createApplicationObject = () => {
  const sessionId = fetchApplicationSessionId() || createApplicationSessionId();
  return {
    sessionId,
  };
};

export default function configureStore(initialState: object, win: Window) {
  // Note: passing middleware as the last argument to createStore requires redux@>=3.1.0
  const applicationObject = createApplicationObject();
  const applicationWindow = win as AppplicationWindow;
  applicationWindow.chomiko = { ...applicationObject, errors, messages };
  const sagaMiddleware = createSagaMiddleware();
  return {
    ...createStore(reducers, initialState, applyMiddleware(/* other middleware, */sagaMiddleware)),
    runSaga: sagaMiddleware.run,
    [APPLICATION_NAME]: applicationObject,
  };
}
